/* eslint-disable react/no-danger */
import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { LoadingButton } from 'components/Loading';
import ArtworkSuggestions from 'components/Suggestions/ArtworkSuggestions';

import CommunSVGs from 'svgs/commun';
import PlayerSVGs from 'svgs/player';

type Props = {
  userProfile: Object,
  artworkName: String,
  artworkAuthor: String,
  artworkCover: String,
  artworkIsFavorite: Boolean,
  nextFlow: Object,
  suggestions: [],
  show: Boolean,
  onGoFirst(): void,
  onNextFlow(): void,
  // onShowControls(): void,
  onSaveFavorite(): void,
  onClosePlayer(): void,
  onClose(): void,
  onNotInterested(): void,
  onSignup(): void,
};

const LastInfo = ({
  artworkName,
  artworkAuthor,
  artworkCover,
  artworkIsFavorite,
  nextFlow,
  suggestions,
  userProfile,
  show,
  // onGoFirst,
  onNextFlow,
  onSaveFavorite,
  // onShowControls,
  onClose,
  onClosePlayer,
  onNotInterested,
  onSignup,
}: Props) => {
  const { t } = useTranslation('player');
  const [followed, setFollowed] = React.useState(false);
  const cover = nextFlow ? artworkUtils.getCoverUrl(nextFlow, 64) : artworkCover;
  const hasSuggestions = suggestions && suggestions.length > 0;
  return (
    <div className={`Player-Info-lastfile Player-modal ${show ? 'show' : 'hide'}`}>
      <div className="Player-Info-close" onClick={onClose} role="presentation">
        <PlayerSVGs.Close width="24" height="24" fill="#707070" />
      </div>
      <div className="d-flex flex-column flex-lg-row">
        <div className={`Player-Info-lastfile-Wrapper${hasSuggestions ? ' suggest' : ''}`}>
          <div className="Player-Info-lastfile-title">{t('lastfile-button')}</div>
          {/*
          <Button variant="link" className="Player-Info-lastfile-gofirst" onClick={onGoFirst}>
            {t('firstfile-button')}
  </Button> */}
          <div className="Player-Info-lastfile-joke">{t('lastfile-joke')}</div>
          <div className="Player-Info-lastfile-artwork pb-3">
            <img src={cover} alt="" />
            <div className="Player-Info-lastfile-artworkinfo">
              <div>{artworkName}</div>
              <div>{artworkAuthor?.username}</div>
              {nextFlow && (
                <div className="Player-Info-lastfile-artwork-nextflow">
                  <div className="font-weight-normal">{t('nextflow')}</div>
                  {nextFlow.isFlow ? nextFlow.flowName || t('global:default-flow-name') : nextFlow.name}
                </div>
              )}
            </div>
          </div>
          {acl.isConnected(userProfile) && !!nextFlow && (
            <Button variant="pinkRed" size="md" onClick={onNextFlow} className="btn-pill mt-1 mb-3">
              {t('nextflow-button')}
            </Button>
          )}
          {acl.isConnected(userProfile) && (followed || !artworkIsFavorite) && (
            <div className="Player-Info-lastfile-favorite">
              {artworkIsFavorite && followed && (
                <div>
                  {t('favorite-confirmation')}{' '}
                  <CommunSVGs.FavoritesWhite className="mb-1" width="16" height="16" fill="#BBB" />
                </div>
              )}
              {!artworkIsFavorite && (
                <>
                  <div>{t('favorite-title')}</div>
                  <LoadingButton
                    variant="secondary"
                    size="md"
                    className="btn-pill mt-3"
                    isFetching={followed}
                    onClick={() => {
                      onSaveFavorite();
                      setFollowed(true);
                    }}
                    label={
                      <>
                        <span className="mr-1">{t('favorite-button')}</span>
                        <CommunSVGs.Favorites className="mb-1" width="16" height="16" fill="#BBB" />
                      </>
                    }
                  />
                </>
              )}
            </div>
          )}
          {!acl.isConnected(userProfile) && (
            <div>
              <div>{t('signup-title')}</div>
              <Button variant="secondary" size="md" className="btn-pill mt-3 ml-5 mr-5" onClick={onSignup}>
                {t('signup-button')}
              </Button>
              <div className="mt-3">{t('signup-features-title')}</div>
              <div className="mt-1" dangerouslySetInnerHTML={{ __html: t('signup-features-paragraph') }} />
            </div>
          )}
          {/* <div className="Player-Info-lastfile-lost" onClick={onShowControls} role="presentation">
            Lost with the controls <PlayerSVGs.HelpControls className="ml-1" width="24px" height="24px" fill="white" />
          </div> */}
          <Button variant="outline-white" size="sm" className="btn-pill mt-3" onClick={onClosePlayer}>
            {t('exit-button')}
          </Button>
        </div>
        <div className="Player-Info-lastfile-suggestions">
          {hasSuggestions && (
            <ArtworkSuggestions
              suggestions={suggestions}
              userProfile={userProfile}
              onNotInterested={onNotInterested}
              shouldUseBanner
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LastInfo);
