import React, { memo } from 'react';

import PlayerSVGs from 'svgs/player';

import styles from './ProgressArrow.module.scss';

interface Props {
  isLeft?: boolean;
}

export default memo(function ProgressArrow({ isLeft }: Props) {
  return isLeft ? (
    <PlayerSVGs.ProgressRightToLeft className={styles.rtl} width="8px" height="8px" />
  ) : (
    <PlayerSVGs.ProgressLeftToRight className={styles.ltr} width="8px" height="8px" />
  );
});
