import cx from 'classnames';

import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';
import artworkUtils from 'tools/artwork';
import { withTheme } from 'tools/theme';

import { getArtworkById, MODE_ALLFILES } from 'actions/ArtworkActions';
import { getArtworkNewsById } from 'actions/NewsActions';
import { showNotification } from 'actions/NotificationsActions';
import { getPackById, getPackFiles } from 'actions/PackActions';
import { getPrivateProfile, getFavoriteArtworks } from 'actions/UserActions';

import Loading from 'components/Loading';

import PlayerSVGs from 'svgs/player';

import FeedbackPanel from './FeedbackPanel';
import Footer from './Footer';
import Header from './Header';
import {
  MODE_FITSCREEN,
  READING_RIGHT_TO_LEFT,
  MODE_FITCOMMENTS,
  changePlayerUrl,
  MODE_FITWIDTH,
  READING_TOP_TO_BOTTOM,
} from './Player-utils';
import './Player.scss';
import PlayerSlider from './PlayerSlider';
import Arrows from './PlayerSlider/partials/Arrows';
import UnlockAction from './PlayerSlider/partials/UnlockAction';

type Props = {
  id: number,
  type: string,
  packState: object,
  artwork: object,
  flowFiles: [],
  totalflowFiles: number,
  currentNews: object,
  page: number,
  sortnumber: number,
  fileId: number,
  viewMode: string,
  userId: number,
  isFetchingArtwork: boolean,
  isFetchingPack: object,
  onClose(): void,
  getPrivateProfileAction(): void,
  getFavoriteArtworksAction(): void,
  getArtworkNewsByIdAction(): void,
  getArtworkByIdAction(): void,
  getPackByIdAction(): void,
  getPackFilesAction(): void,
  showNotificationAction(): void,
};

class Player extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { getPrivateProfileAction, getFavoriteArtworksAction, userId } = this.props;
    if (acl.getToken() && !userId) {
      getPrivateProfileAction();
      getFavoriteArtworksAction();
    }
    this.fetchData();
  }

  componentDidUpdate() {
    this.loadFiles();
  }

  fetchData = () => {
    const {
      artwork,
      flowFiles,
      totalflowFiles,
      getArtworkNewsByIdAction,
      getArtworkByIdAction,
      getPackByIdAction,
      getPackFilesAction,
    } = this.props;
    if (this.getType() === 'news') {
      getArtworkNewsByIdAction(this.getId());
    } else if (this.getType() === 'flow') {
      if (
        !artwork ||
        (artwork.id !== this.getId() && artwork.uniqueName !== this.getId()) ||
        flowFiles.length !== totalflowFiles
      ) {
        getArtworkByIdAction(this.getId(), false, MODE_ALLFILES);
      } else {
        this.loadFiles();
      }
    } else if (this.getType() === 'pack') {
      getPackByIdAction(this.getId());
      getPackFilesAction(this.getId(), 'all');
    } else {
      // eslint-disable-next-line no-console
      console.log('media type unknown !');
    }
  };

  getId = () => {
    const { id } = this.props;
    const { currentFlow } = this.state;
    return currentFlow ? currentFlow.id : id;
  };

  getType = () => {
    const { type } = this.props;
    const { currentFlow } = this.state;
    if (currentFlow) {
      return currentFlow.isFlow ? 'flow' : 'pack';
    }
    return type;
  };

  getPage = () => {
    const { page } = this.props;
    const { currentFlow } = this.state;
    return currentFlow ? undefined : page;
  };

  getSortNumber = () => {
    const { sortNumber } = this.props;
    const { currentFlow } = this.state;
    return currentFlow ? undefined : sortNumber;
  };

  getFileId = () => {
    const { fileId } = this.props;
    const { currentFlow } = this.state;
    return currentFlow ? undefined : fileId;
  };

  loadFiles = () => {
    const { packState, artwork, flowFiles, currentNews, isFetchingArtwork, isFetchingPack, t } = this.props;
    const { files, initIndex } = this.state;
    if (files || isFetchingArtwork || isFetchingPack?.getPackById || isFetchingPack?.getPackFiles) {
      // wait for data loading
      return;
    }
    const newState = {};
    // load files
    if (this.getType() === 'news' && currentNews && currentNews.artworkNewsFiles) {
      newState.files = currentNews.artworkNewsFiles;
      newState.artworkId = currentNews.artwork.id;
      newState.artworkUniqueName = currentNews.artwork.uniqueName;
      newState.artworkUrl = artworkUtils.getArtworkUrl(currentNews.artwork);
      newState.artworkAuthor = currentNews.artwork.author;
      newState.artworkName = currentNews.artwork.name;
      newState.artworkCover = artworkUtils.getCoverUrl(currentNews.artwork, 64);
      newState.flowName = currentNews.artwork.flowName || t('global:default-flow-name');
    }
    if (
      this.getType() === 'flow' &&
      artwork &&
      (`${artwork.id}` === `${this.getId()}` || `${artwork.uniqueName}` === `${this.getId()}`) &&
      flowFiles
    ) {
      newState.files = flowFiles;
      newState.readingDirection = artwork.readingDirection;
      newState.artworkId = artwork.id;
      newState.artworkUniqueName = artwork.uniqueName;
      newState.artworkUrl = artworkUtils.getArtworkUrl(artwork);
      newState.nextFlow = artwork.nextFlow;
      newState.artworkAuthor = artwork.author;
      newState.artworkName = artwork.name;
      newState.artworkCover = artworkUtils.getCoverUrl(artwork, 64);
      newState.flowName = artwork.flowName || t('global:default-flow-name');
      newState.price = artwork.price;
    }
    if (
      this.getType() === 'pack' &&
      packState.current &&
      `${packState.current.id}` === `${this.getId()}` &&
      packState[packState.current.id]
    ) {
      newState.files = packState[packState.current.id];
      newState.readingDirection = packState.current.artwork.readingDirection;
      newState.artworkId = packState.current.artwork.id;
      newState.artworkUniqueName = packState.current.artwork.uniqueName;
      newState.artworkUrl = artworkUtils.getArtworkUrl(packState.current.artwork);
      newState.artworkAuthor = packState.current.artwork.author;
      newState.artworkName = packState.current.artwork.name;
      newState.artworkCover = artworkUtils.getCoverUrl(packState.current.artwork, 64);
      newState.packId = packState.current.id;
      newState.price = packState.current.price;
      newState.packCover = artworkUtils.getCoverUrl(packState.current, 64);
      newState.flowName = packState.current.name;
      if (packState.current.isNextFlowArtwork) {
        newState.nextFlow = packState.current.artwork;
      } else {
        newState.nextFlow = packState.current.nextFlow;
      }
    }
    let fileId = this.getFileId();
    // update state if necessary
    if (newState.files && newState.files.length > 0) {
      // ORDER
      newState.files.sort((fileA, fileB) => {
        if (newState.readingDirection === READING_RIGHT_TO_LEFT) {
          return fileB.sortNumber - fileA.sortNumber; // revert order for manga
        }
        return fileA.sortNumber - fileB.sortNumber;
      });
      const page = this.getPage();
      const sortNumber = this.getSortNumber();
      let imgIndex = 0;
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(sortNumber)) {
        newState.files.forEach((file, index) => {
          if (file.sortNumber === sortNumber) {
            imgIndex = index;
          }
        });
        // eslint-disable-next-line no-restricted-globals
      } else if (!isNaN(page - 1) && page <= newState.files.length && page > 0) {
        imgIndex = page - 1;
        // eslint-disable-next-line no-restricted-globals
      } else if (!isNaN(fileId)) {
        newState.files.forEach((file, index) => {
          if (file.file.id === fileId) {
            imgIndex = index;
          }
        });
      } else {
        imgIndex = newState.readingDirection === READING_RIGHT_TO_LEFT ? newState.files.length - 1 : 0;
      }
      newState.filePath = changePlayerUrl(this.getType(), this.getId(), fileId, newState.artworkUniqueName);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        ...newState,
        initIndex: initIndex || imgIndex,
      });
      fileId = newState.files[initIndex || imgIndex].file.id;
    } else if (newState.files) {
      newState.filePath = changePlayerUrl(this.getType(), this.getId(), fileId, newState.artworkUniqueName);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(newState);
    }
  };

  onNextFlow = () => {
    const { flowName } = this.state;
    TagManager.dataLayer({
      dataLayer: {
        action: 'Show next flow',
        label: flowName,
      },
      dataLayerName: 'PlayerDataLayer',
    });
    this.setState(
      state => ({
        files: null,
        readingDirection: null,
        initIndex: null,
        artworkId: null,
        nextFlow: null,
        currentFlow: state.nextFlow,
      }),
      () => this.fetchData(),
    );
  };

  handleRefresh = (initIndex, silent) => {
    const { showNotificationAction } = this.props;
    if (!silent) {
      showNotificationAction({ level: 'error', message: 'Failed to load image, refresh in progress...' });
    }
    this.fetchData();
    this.setState({ files: null, initIndex });
  };

  handleClosePlayer = () => {
    const { artworkUrl, onClose, history } = this.props;
    if (onClose) {
      onClose(); // call for modal
    } else {
      history.push(artworkUrl);
    }
  };

  render() {
    const {
      files,
      readingDirection,
      initIndex,
      artworkId,
      artworkUrl,
      artworkUniqueName,
      artworkAuthor,
      artworkName,
      artworkCover,
      packId,
      packCover,
      price,
      filePath,
      flowName,
      nextFlow,
      currentFlow,
    } = this.state;
    const { viewMode: askedViewMode, type, userCart, onClose, theme, t } = this.props;
    if (!files) {
      return (
        <div className="Player d-flex h-100">
          <Header
            show
            closeHandler={this.handleClosePlayer}
            readingDirection={readingDirection}
            type={type}
            artworkId={artworkId}
            artworkUrl={artworkUrl}
            artworkName={artworkName}
            artworkCover={artworkCover}
            packCover={packCover}
            flowName={flowName}
            isLoading
          />
          <Arrows showMenu viewMode={askedViewMode || MODE_FITSCREEN} readingDirection={readingDirection} isLoading />
          <div className="my-auto mx-auto cursor" onClick={onClose} role="presentation">
            <Loading color="var(--secondary)" />
            <div className="text-white flowfont-medium mt-2">{t('global:loading')}...</div>
          </div>
          <UnlockAction type={type} packId={packId} artworkId={artworkId} price={price} userCart={userCart} isLoading />
          {typeof document !== 'undefined' && (
            <FeedbackPanel viewMode={askedViewMode || MODE_FITSCREEN} nbComments={-1} isLoading />
          )}
          <Footer
            fileName="Loading..."
            viewMode={askedViewMode || MODE_FITSCREEN}
            show
            type={type}
            artworkId={artworkId}
            artworkUrl={artworkUrl}
            artworkName={artworkName}
            artworkCover={artworkCover}
            packCover={packCover}
            flowName={flowName}
            changeModeHandler={this.handleChangeMode}
            showControlsHandler={this.handleShowControls}
            isLoading
          />
        </div>
      );
    }
    if (files.length === 0) {
      return (
        <div className="Player d-flex h-100">
          <div className="my-auto mx-auto text-white flowfont-medium">
            {t('empty')} <PlayerSVGs.Close width="24" height="24" fill="#707070" onClick={onClose} />
          </div>
        </div>
      );
    }
    let viewMode = MODE_FITSCREEN;
    if (readingDirection === READING_TOP_TO_BOTTOM) {
      // webtoon
      viewMode = MODE_FITWIDTH;
    }
    if (askedViewMode === 'fitcomment') {
      viewMode = MODE_FITCOMMENTS;
    }
    return (
      <div className={cx('Player h-100', theme)}>
        <PlayerSlider
          type={this.getType()}
          id={this.getId()}
          artworkId={artworkId}
          artworkUniqueName={artworkUniqueName}
          artworkUrl={artworkUrl}
          artworkAuthor={artworkAuthor}
          artworkName={artworkName}
          artworkCover={artworkCover}
          packId={packId}
          packCover={packCover}
          price={price}
          flowName={flowName}
          filePath={filePath}
          files={files}
          readingDirection={readingDirection}
          index={initIndex}
          viewMode={viewMode}
          nextFlow={nextFlow}
          onClose={onClose}
          onNextFlow={this.onNextFlow}
          onRefresh={this.handleRefresh}
          firstFlow={!currentFlow}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.user?.privateProfile?.id,
  userCart: state.user?.cart,
  packState: state.pack,
  currentNews: state.news.current,
  artwork: state.artwork.current,
  flowFiles: state.artwork.files,
  totalflowFiles: state.artwork.totalFiles,
  isFetchingArtwork: state.artwork?.isFetching?.getArtworkById,
  isFetchingPack: state.pack?.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getPrivateProfileAction: () => dispatch(getPrivateProfile()),
  getFavoriteArtworksAction: () => dispatch(getFavoriteArtworks()),
  getPackByIdAction: (id, forceUpdate, mode) => dispatch(getPackById(id, forceUpdate, mode)),
  getPackFilesAction: (id, page) => dispatch(getPackFiles(id, page)),
  getArtworkByIdAction: (id, forceUpdate, mode) => dispatch(getArtworkById(id, forceUpdate, mode)),
  getArtworkNewsByIdAction: id => dispatch(getArtworkNewsById(id)),
  showNotificationAction: message => dispatch(showNotification(message)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation('player')(withTheme(Player))));
