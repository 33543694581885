import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import PlayerSVGs from 'svgs/player';

import './Header.scss';
import ProgressBar from './ProgressBar';

type Props = {
  index: Number,
  files: Array,
  readingDirection: string,
  isLoading: boolean,
  changeSlideHandler(): void,
  closeHandler(): void,
};
const Header = ({ readingDirection, index, files, isLoading, closeHandler, changeSlideHandler }: Props) => {
  return (
    <>
      <div className="Player-Header">
        <OverlayTrigger
          key="close"
          placement="bottom"
          trigger={['hover', 'focus']}
          overlay={<Tooltip id="tooltip-close">Close</Tooltip>}
        >
          <PlayerSVGs.Close width="40" height="40" fill="#707070" onClick={closeHandler} />
        </OverlayTrigger>
      </div>
      <ProgressBar
        files={files}
        index={index}
        readingDirection={readingDirection}
        isLoading={isLoading}
        changeSlideHandler={changeSlideHandler}
      />
    </>
  );
};

export default React.memo(Header);
