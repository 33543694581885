import cx from 'classnames';

import React from 'react';

import { useMediaQuery } from 'react-responsive';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { MEDIA_LG } from 'constants/Constants';

import Cover from 'components/Cover';

import PlayerSVGs from 'svgs/player';

import { MODE_FITBOX, MODE_FITSCREEN, MODE_FITWIDTH, MODE_FITCOMMENTS } from '../Player-utils';
import './Footer.scss';

type Props = {
  fileName: String,
  viewMode: string,
  show: Boolean,
  type: string,
  artworkUrl: string,
  artworkName: string,
  artworkCover: string,
  packCover: string,
  flowName: string,
  isLoading: boolean,
  changeModeHandler(): void,
  showControlsHandler(): void,
};

const Footer = ({
  fileName,
  viewMode,
  show,
  type,
  artworkUrl,
  artworkName,
  artworkCover,
  packCover,
  flowName,
  isLoading,
  changeModeHandler,
  showControlsHandler,
}: Props) => {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });

  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const handleFullScreen = () => {
    const elem = document.documentElement;
    if (isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    } else if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  };

  let title = 'Loading...';
  if (fileName) {
    const fileNameParts = fileName.split('/');
    title = fileNameParts[fileNameParts.length - 1];
  }

  // const nextViewMode = VIEWMODE_SEQ[viewMode];
  let viewModeIcon;
  if (viewMode === MODE_FITBOX || viewMode === MODE_FITCOMMENTS)
    viewModeIcon = (
      <PlayerSVGs.FitBox
        width="24"
        height="24"
        fill="#707070"
        className="Player-Footer-icon mr-3"
        onClick={changeModeHandler}
      />
    );
  if (viewMode === MODE_FITSCREEN)
    viewModeIcon = (
      <PlayerSVGs.FitScreen
        width="24"
        height="24"
        fill="#707070"
        className="Player-Footer-icon mr-3"
        onClick={changeModeHandler}
      />
    );
  if (viewMode === MODE_FITWIDTH)
    viewModeIcon = (
      <PlayerSVGs.FitWidth
        width="24"
        height="24"
        fill="#707070"
        className="Player-Footer-icon mr-3"
        onClick={changeModeHandler}
      />
    );

  return (
    <>
      <div
        className={cx('Player-Footer Player-Footer-secondary d-flex', {
          show,
          hide: !show,
          animated: show && isLoading,
          fadeInLeft: show,
          'delay-1s': show,
        })}
      >
        <div className="Player-Footer-title">
          {!isMobile && (
            <a className={`Player-Footer-artwork ${show ? 'show' : 'hide'}`} href={artworkUrl}>
              <Cover src={type === 'pack' ? packCover : artworkCover} className="Player-Footer-artwork-cover" />
              <p>
                {artworkName}
                <br />
                {flowName}
              </p>
            </a>
          )}
        </div>
        <div className="Player-Footer-actions">
          {viewModeIcon && (
            <OverlayTrigger
              key="mode"
              placement="top"
              trigger={['hover', 'focus']}
              overlay={<Tooltip id="tooltip-mode">Current view mode</Tooltip>}
            >
              {viewModeIcon}
            </OverlayTrigger>
          )}
          <OverlayTrigger
            key="fullscreen"
            placement="top"
            trigger={['hover', 'focus']}
            overlay={<Tooltip id="tooltip-fullscreen">Fullscreen</Tooltip>}
          >
            {isFullScreen ? (
              <PlayerSVGs.FullscreenExit
                className="Player-Footer-icon mr-3"
                width="24"
                height="24"
                fill="#707070"
                onClick={handleFullScreen}
              />
            ) : (
              <PlayerSVGs.Fullscreen
                className="Player-Footer-icon mr-3"
                width="24"
                height="24"
                fill="#707070"
                onClick={handleFullScreen}
              />
            )}
          </OverlayTrigger>
          <OverlayTrigger
            key="settings"
            placement="top"
            trigger={['hover', 'focus']}
            overlay={<Tooltip id="tooltip-settings">Controls</Tooltip>}
          >
            <PlayerSVGs.HelpControls
              className="Player-Footer-icon mr-3"
              width="24"
              height="24"
              fill="#707070"
              onClick={showControlsHandler}
            />
          </OverlayTrigger>
          <div>{title}</div>
        </div>
      </div>
      {/* <div className="Player-Footer Player-Footer-main d-flex show">
          <div className="Player-Footer-actions">
            <OverlayTrigger
              key="comments"
              placement="top"
              trigger={['hover', 'focus']}
              overlay={<Tooltip id="tooltip-comments">Comments</Tooltip>}
            >
              <div className="Player-Footer-icon comments cursor" onClick={showCommentsHandler} role="presentation">
                {nbComments > 0 && <Badge className={`animated${isAnimating ? ' heartBeat' : ''}`}>{nbComments}</Badge>}
                <PlayerSVGs.Comments
                  className={`animated${isAnimating ? ' heartBeat' : ''}`}
                  width="34"
                  height="34"
                  fill="#BBB"
                />
              </div>
            </OverlayTrigger>
          </div>
        </div> */}
    </>
  );
};

export default React.memo(Footer);
