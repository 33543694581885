import cx from 'classnames';

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';

import _get from 'lodash/get';
import _keyBy from 'lodash/keyBy';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import acl from 'tools/acl';

import { addFileFeedback } from 'actions/FileActions';

import PlayerSVGs from 'svgs/player';

import './FeedbackPanel.scss';
import FeedbackSmiley from './FeedbackSmiley/FeedbackSmiley';

type Props = {
  file: string,
  viewMode: string,
  nbComments: number,
  userProfile: Object,
  isLoading: boolean,
  onShowComments(): void,
};

const FeedbackPanel = ({ file, viewMode, nbComments, isLoading, userProfile, onShowComments }: Props) => {
  const feedbackCounts = useSelector(state => _get(state.file, 'feedbacks.feedbackCounts'));
  const currentFeedback = useSelector(state => _get(state.file, 'feedbacks.feedback'));
  const dispatch = useDispatch();

  const feedbacks = _keyBy(feedbackCounts, 'feedback');

  const [isAnimating, setIsAnimating] = React.useState(false);
  const [userFeedback, setUserFeedback] = React.useState(currentFeedback);

  const handleClick = feedback => {
    dispatch(addFileFeedback(file.file.id, feedback));
    setUserFeedback(feedback);
  };

  React.useEffect(() => {
    if (nbComments > 0) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 750);
    }
  }, [nbComments]);

  React.useEffect(() => {
    setUserFeedback(currentFeedback);
  }, [currentFeedback]);

  const disabled = isLoading || !acl.isConnected(userProfile);
  return (
    <div className={cx('Player-feedbacks', viewMode, 'zoomIn', 'delay-0.5s', { animated: isLoading })}>
      <FeedbackSmiley
        disabled={disabled}
        variant="funny"
        count={_get(feedbacks, 'funny.count')}
        active={userFeedback === 'funny'}
        onClick={handleClick}
      />
      <FeedbackSmiley
        disabled={disabled}
        variant="surprising"
        count={_get(feedbacks, 'surprising.count')}
        active={userFeedback === 'surprising'}
        onClick={handleClick}
      />
      <OverlayTrigger
        key="comments"
        placement="bottom"
        trigger={['hover', 'focus']}
        overlay={<Tooltip id="tooltip-comments">Comments</Tooltip>}
      >
        <div
          onClick={onShowComments}
          className={`Player-feedbacks-smile${nbComments > 0 ? ' active' : ''}`}
          role="presentation"
        >
          <span className={`count animated${isAnimating ? ' heartBeat' : ''}`}>
            {nbComments >= 0 ? nbComments : ''}
          </span>
          <PlayerSVGs.Comments2 className={`animated${isAnimating ? ' heartBeat' : ''}`} />
        </div>
      </OverlayTrigger>
      <FeedbackSmiley
        disabled={disabled}
        variant="sad"
        count={_get(feedbacks, 'sad.count')}
        active={userFeedback === 'sad'}
        onClick={handleClick}
      />
      <FeedbackSmiley
        disabled={disabled}
        variant="lovely"
        count={_get(feedbacks, 'lovely.count')}
        active={userFeedback === 'lovely'}
        onClick={handleClick}
      />
    </div>
  );
};

export default React.memo(FeedbackPanel);
