import cx from 'classnames';

import React, { useRef, useState } from 'react';

import { useMediaQuery } from 'react-responsive';

import { MEDIA_LG } from 'constants/Constants';

import artworkUtils from 'tools/artwork';

import Cover from 'components/Cover';

import { READING_RIGHT_TO_LEFT } from '../Player-utils';
import styles from './ProgressBar.module.scss';
import ProgressArrow from './partials/ProgressArrow';
import ProgressFirstBar from './partials/ProgressFirstBar';

interface Props {
  files: Object[];
  index: number;
  readingDirection: string;
  isLoading: boolean;
  changeSlideHandler(): void;
}

export default function ProgressBar({ files = [], index, readingDirection, isLoading, changeSlideHandler }: Props) {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  const maxPage = files?.length;
  const ref = useRef();
  const [previewPage, setPreviewPage] = useState();
  const [reversePreviewDirection, setReversePreviewDirection] = useState();

  const isReverse = readingDirection === READING_RIGHT_TO_LEFT;
  const getPageFromIndex = currIndex => (isReverse ? maxPage - currIndex : currIndex + 1);
  const currentPage = getPageFromIndex(index);
  const direction = isReverse ? 'rtl' : 'ltr';

  const handleHover = curr => e => {
    if (isMobile) return;
    setReversePreviewDirection(ref.current.clientWidth - e.clientX < 200);
    setPreviewPage(curr + 1);
  };

  return (
    <div className={cx(styles.ProgressBar, 'fadeInDown', 'delay-1s', { animated: isLoading })} ref={ref}>
      {isLoading && (
        <div className={styles.page}>
          <div className={cx(styles.bar, styles[direction], styles.loading)} />
        </div>
      )}
      {files.map((file, curr) => (
        <button
          key={JSON.stringify(file)}
          className={styles.page}
          type="button"
          onClick={() => changeSlideHandler('PAGE', curr + 1)}
          onMouseEnter={handleHover(curr)}
          onMouseLeave={() => setPreviewPage(false)}
        >
          <div
            className={cx(styles.bar, styles[direction], {
              [styles.active]: isReverse ? curr >= index : curr <= index,
            })}
          >
            {(isReverse ? curr === maxPage - 1 : curr === 0) && (
              <ProgressFirstBar
                direction={direction}
                currentPage={currentPage}
                maxPage={maxPage}
                changeSlideHandler={changeSlideHandler}
              />
            )}
            {curr === index && <ProgressArrow isLeft={isReverse} />}
          </div>
          {previewPage === curr + 1 && (
            <div className={cx(styles.preview, { [styles.reverse]: reversePreviewDirection })}>
              <span className={styles.num}>{getPageFromIndex(curr)}</span>
              <Cover src={artworkUtils.getFileUrl(file.file, 70)} className={styles.cover} />
              <span className={styles.title}>{file.file.name}</span>
            </div>
          )}
        </button>
      ))}
      {!!previewPage && <div className={styles.previewZone} />}
    </div>
  );
}
