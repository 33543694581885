import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import artworkUtils from 'tools/artwork';

import PlayerSVGs from 'svgs/player';

const smileysIcons = {
  funny: PlayerSVGs.SmileyFunny,
  surprising: PlayerSVGs.SmileySurprising,
  sad: PlayerSVGs.SmileySad,
  lovely: PlayerSVGs.SmileyLovely,
  angry: PlayerSVGs.SmileyAngry,
};

type Props = {
  variant: string,
  count: Number,
  active: Boolean,
  animation: String,
  disabled: Boolean,
  onClick: Function,
};

const FeedbackSmiley = ({ variant, count, active, animation, disabled, onClick }: Props) => {
  const [isAnimating, setIsAnimating] = React.useState(false);

  const handleClick = () => {
    if (!disabled) {
      setIsAnimating(true);
      setTimeout(() => {
        setIsAnimating(false);
      }, 500);
      onClick(variant);
    }
  };

  const SvgIcon = smileysIcons[variant];
  return (
    <OverlayTrigger
      key={`feedback-${variant}`}
      placement="bottom"
      trigger={['hover', 'focus']}
      overlay={<Tooltip id="tooltip-comments">So {variant}</Tooltip>}
    >
      <div
        onClick={handleClick}
        className={`Player-feedbacks-smile${active ? ' active' : ''}${disabled ? ' disabled' : ''} animated${
          isAnimating ? ` ${animation || 'flash'}` : ''
        }`}
        role="presentation"
      >
        <span className="count">{artworkUtils.formatCount(count)}</span>
        <SvgIcon />
      </div>
    </OverlayTrigger>
  );
};

export default FeedbackSmiley;
