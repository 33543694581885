import React from 'react';

import Helmet from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';

import _isNil from 'lodash/isNil';

import seo from 'tools/seo';

import { getArtworkFile } from 'actions/ArtworkActions';
import { getPackFile } from 'actions/PackActions';

import CartModal from 'components/Modal/CartModal';
import Player from 'components/Player';

import './PlayerPage.scss';

type Props = {
  match: Object,
};

const toInt = value => (value ? parseInt(value, 10) : value);

const PlayerPage = ({ match: { params } }: Props) => {
  const packFile = useSelector(state => state.pack.file);
  const artworkFile = useSelector(state => state.artwork.file);
  const cartMode = useSelector(state => state.user?.cartMode);

  const dispatch = useDispatch();
  // componentDidMount() {
  //   this.interval = setInterval(() => {
  //     if (helpscout.forceHide()) clearInterval(this.interval);
  //   }, 750);
  // }

  // componentWillUnmount() {
  //   clearInterval(this.interval);
  //   helpscout.forceShow();
  // }

  if (typeof document === 'undefined' && params.fileId && !packFile && !artworkFile) {
    if (params.type === 'pack') {
      dispatch(getPackFile(params.fileId)); // SSR
    } else {
      dispatch(getArtworkFile(params.fileId)); // SSR
    }
  }
  const file = artworkFile || packFile;
  return (
    <div className="PlayerPage h-100">
      <Helmet>
        {seo.title(`Read ${params.artworkId || params.id} on Flowfo`)}
        {file && seo.image(file.thumbnailUrl)}
        {seo.noIndex()}
      </Helmet>
      <Player
        type={params.type}
        id={params.id}
        page={toInt(params.page)}
        fileId={toInt(params.fileId)}
        viewMode={params.mode}
      />
      {!_isNil(cartMode) && cartMode !== false && <CartModal />}
    </div>
  );
};

export default PlayerPage;
