import cx from 'classnames';

import React, { memo } from 'react';

import styles from './ProgressFirstBar.module.scss';

interface Props {
  direction: String;
  currentPage: number;
  maxPage: number;
  changeSlideHandler(): void;
}

export default memo(function ProgressFirstBar({ direction, currentPage, maxPage, changeSlideHandler }: Props) {
  const pageInfo = (
    <span className={styles.pageProgress}>
      {currentPage} / {maxPage}
    </span>
  );
  const firstLink = (
    <span
      className={styles.first}
      onClick={() => changeSlideHandler(direction === 'rtl' ? 'LAST' : 'FIRST')}
      role="presentation"
    >
      First
    </span>
  );
  return direction === 'rtl' ? (
    <div className={cx(styles.info, direction)}>
      {pageInfo} {firstLink}
    </div>
  ) : (
    <div className={cx(styles.info, direction)}>
      {firstLink} {pageInfo}
    </div>
  );
});
