import cx from 'classnames';

import React, { Component, memo } from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';

import { TagManager } from 'tools/analytics';
import artworkUtils from 'tools/artwork';
import { withMobileMediaQuery } from 'tools/responsive';

import { markAsReadFileArtwork, getSuggestions, markNotInterested } from 'actions/ArtworkActions';
import { getFileComments } from 'actions/CommentActions';
import { getFileFeedbacks } from 'actions/FileActions';
import { markAsReadFilePack } from 'actions/PackActions';
import { acceptTutorial, addFollowedArtwork } from 'actions/UserActions';

import { LoadingButton } from 'components/Loading';
import LoginModal from 'components/Login/LoginModal';

import FeedbackPanel from '../FeedbackPanel';
import Footer from '../Footer';
import Header from '../Header';
import {
  loadImage,
  getSwipeDirection,
  changePlayerUrl,
  getImageCenter,
  getPointFromTouch,
  getDistanceBetweenPoints,
  getMidpoint,
  between,
  VIEWMODE_SEQ,
  MODE_FITBOX,
  MODE_FITWIDTH,
  READING_LEFT_TO_RIGHT,
  READING_TOP_TO_BOTTOM,
  READING_RIGHT_TO_LEFT,
  DOUBLE_TAP_SENSITIVITY,
  MENU_TIMER,
  MARK_AS_READ_TIMEOUT,
  OPENING_INFO_TIMEOUT,
  MODE_FITCOMMENTS,
  PINCH_MIN_SCALE,
  PINCH_MAX_SCALE,
  PINCH_ADDITIONAL_LIMIT,
  TOUCH_MOVE_SENSITIVIY_X,
  TOUCH_MOVE_SENSITIVIY_Y,
  PINCH_SENSITIVITY,
  inverse,
  SWIPE_DIRECTION_DOWN,
  SWIPE_DIRECTION_UP,
  SWIPE_DIRECTION_RIGHT,
  SWIPE_DIRECTION_LEFT,
  SWIPE_DIRECTION_NONE,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
  WEBTOON_SCROLL_SPEED_RATIO,
  SLIDE_ANIMATED,
} from '../Player-utils';
import PlayerComments from '../PlayerComments';
import PlayerSlide from '../PlayerSlide';
import './PlayerAnimation.scss';
import './PlayerSlider.scss';
import PlayerWebtoonSlider from './PlayerWebtoonSlider';
import Arrows from './partials/Arrows';
import Controls from './partials/Controls';
import LastInfo from './partials/LastInfo';
import OpeningInfo from './partials/OpeningInfo';
import TutorialControls, { tutorialId as tutorialControlsId } from './partials/TutorialControls';
import UnlockAction from './partials/UnlockAction';
import VisitorComment from './partials/VisitorComment';

const DEFAULT_PINCH = { scale: PINCH_MIN_SCALE, x: 0, y: 0 };

interface Props {
  type: string;
  id: number;
  artworkId: number;
  artworkUniqueName: string;
  artworkUrl: string;
  artworkAuthor: Object;
  artworkName: string;
  artworkCover: string;
  packId: number;
  packCover: string;
  flowName: string;
  suggestions: [];
  filePath: string;
  viewMode: string;
  index: number;
  readingDirection: string;
  files: Array;
  posts: Array;
  nextFlow: Object;
  firstFlow: boolean;
  onNextFlow(): void;
  onRefresh(): void;
  userProfile: Object;
  userCart: Object;
  favoriteArtworks: Array;
  acceptedTutorials: Array;
  onClose(): void;
  history: Object;
  isMobile: boolean;
  getFileCommentsAction(): void;
  markAsReadFileArtworkAction(): void;
  markAsReadFilePackAction(): void;
  getFileFeedbacksAction(): void;
  addFollowedArtworkAction(): void;
  acceptTutorialAction(): void;
  getSuggestionsAction(): void;
  markNotInterestedAction(): void;
}

class PlayerSlider extends Component<Props> {
  constructor(props) {
    super(props);
    const { viewMode, index, filePath } = this.props;
    this.state = {
      viewMode,
      currIndex: index,
      filePath,
      showMenu: false,
      scrollTo: 0,
      pinch: DEFAULT_PINCH,
      slideUpdated: true,
      showOpening: false,
      showLast: false,
      showControls: false,
      showVisitorComment: false,
      showTutorial: false,
      loginModalMode: false,
      commentsFocused: false,
      commentsPosition: { left: -4000, top: 0, height: 0 },
      imgError: false,
      overImage: false,
    };
    this.dragging = false;
    this.lastClickTime = 0;
    this.slides = [];
    this.imageLoaded = [];
    this.debugEvent = [];

    this.keyMap = {};
    this.callbackTimers = [];

    this.touchObject = {};

    this.onWindowResized = this.onWindowResized.bind(this);
  }

  /* ******************************
   * LOADING
   ****************************** */

  componentDidMount() {
    if (this.slider?.innerSlider?.list) {
      this.slider.innerSlider.list.addEventListener('mousedown', this.swipeStart);
      this.slider.innerSlider.list.addEventListener('mousemove', this.handleMouseMove);
      this.slider.innerSlider.list.addEventListener('mouseup', this.swipeEnd);
    } else if (this.slider?.webtoonSlider) {
      this.slider.webtoonSlider.addEventListener('mousedown', this.swipeStart);
      this.slider.webtoonSlider.addEventListener('mousemove', this.handleMouseMove);
      this.slider.webtoonSlider.addEventListener('mouseup', this.swipeEnd);
    }
    window.addEventListener('wheel', this.handleWheel);
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);
    window.addEventListener('resize', this.onWindowResized);
  }

  componentDidUpdate() {
    const { viewMode, commentsPosition } = this.state;
    if (viewMode === MODE_FITCOMMENTS) {
      const newPos = this.computeCommentsPosition();
      if (newPos.left !== commentsPosition.left) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          commentsPosition: newPos,
        });
      }
    }
  }

  componentWillUnmount() {
    if (this.slider?.innerSlider?.list) {
      this.slider.innerSlider.list.removeEventListener('mousedown', this.swipeStart);
      this.slider.innerSlider.list.removeEventListener('mousemove', this.handleMouseMoveDebounce);
      this.slider.innerSlider.list.removeEventListener('mouseup', this.swipeEnd);
    } else if (this.slider?.webtoonSlider) {
      this.slider.webtoonSlider.removeEventListener('mousedown', this.swipeStart);
      this.slider.webtoonSlider.removeEventListener('mousemove', this.handleMouseMoveDebounce);
      this.slider.webtoonSlider.removeEventListener('mouseup', this.swipeEnd);
    }
    window.removeEventListener('wheel', this.handleWheel);
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
    window.removeEventListener('resize', this.onWindowResized);
    this.slides = [];
    this.slider = null;
    this.keyMap = {};
    this.lastClickTime = 0;
    clearTimeout(this.changeSlideTimeout);
    clearTimeout(this.menuStateTime);
    if (this.callbackTimers.length) {
      this.callbackTimers.forEach(timer => clearTimeout(timer));
      this.callbackTimers = [];
    }
  }

  /* ******************************
   * MOUSE AND TOUCH EVENTS
   ****************************** */

  // eslint-disable-next-line react/sort-comp
  handleMouseMove = e => {
    this.handleShowMenu();
    if (this.dragging) {
      this.swipeMove(e);
    }
  };

  handleWheel = (e, manual) => {
    const { readingDirection } = this.props;
    const { currIndex, viewMode, commentsFocused, showLast } = this.state;
    if (showLast) return;
    if (viewMode === MODE_FITCOMMENTS && commentsFocused) return;
    if (readingDirection === READING_TOP_TO_BOTTOM) {
      this.slider.handleWheel(e, manual);
    } else {
      this.slides[currIndex].handleWheel(e, manual);
    }
  };

  // COMMANDS SHORTCUTS
  handleKeyDown = event => {
    const { readingDirection } = this.props;
    const { currIndex, viewMode, commentsFocused, debug } = this.state;
    if (viewMode === MODE_FITCOMMENTS && commentsFocused) return;
    this.keyMap[event.key] = true;
    switch (event.key) {
      case 'Enter':
        this.handleChangeMode();
        break;
      case 'ArrowRight':
        if (this.keyMap.Shift) {
          // Shift + ->
          this.handleChangeSlide('LAST');
        } else if (readingDirection === READING_TOP_TO_BOTTOM) {
          this.slider.runBigScroll(SCROLL_DIRECTION_DOWN);
        } else {
          this.slides[currIndex].runBigScroll(
            readingDirection === READING_RIGHT_TO_LEFT ? SCROLL_DIRECTION_UP : SCROLL_DIRECTION_DOWN,
          );
        }
        break;
      case ' ': // SPACE
        if (readingDirection === READING_TOP_TO_BOTTOM) {
          this.slider.runBigScroll(SCROLL_DIRECTION_DOWN);
        } else {
          this.slides[currIndex].runBigScroll(SCROLL_DIRECTION_DOWN);
        }
        break;
      case 'ArrowLeft':
        if (this.keyMap.Shift) {
          // Shift + <-
          this.handleChangeSlide('FIRST');
        } else if (readingDirection === READING_TOP_TO_BOTTOM) {
          this.slider.runBigScroll(SCROLL_DIRECTION_UP);
        } else {
          this.slides[currIndex].runBigScroll(
            readingDirection === READING_RIGHT_TO_LEFT ? SCROLL_DIRECTION_DOWN : SCROLL_DIRECTION_UP,
          );
        }
        break;
      case 'ArrowUp':
      case 'PageUp':
        this.handleWheel({ deltaY: -1 });
        break;
      case 'ArrowDown':
      case 'PageDown':
        this.handleWheel({ deltaY: 1 });
        break;
      case 'Escape':
        this.handleClosePlayer();
        break;
      case 'B':
        if (this.keyMap.Control) {
          this.setState({ debug: !debug });
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(event.key);
        break;
    }
  };

  // for multikey pressed
  handleKeyUp = event => {
    this.keyMap[event.key] = false;
  };

  // handle responsive resizing
  onWindowResized = () => {
    this.forceUpdate();
  };

  handleClick = event => {
    const { readingDirection, isMobile } = this.props;
    const { currIndex, pinch } = this.state;
    // manual click handler, to enable swipe (no element in front of the slider)
    const cursorX = event.touches && event.touches[0] ? event.touches[0].pageX : event.pageX;
    // const cursorY = event.touches && event.touches[0] ? event.touches[0].pageY : event.pageY;
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(cursorX)) return;
    const isZoomIn = pinch && pinch.scale > PINCH_MIN_SCALE;
    const sliderWidth = (this.slider?.innerSlider?.list || this.slider?.webtoonSlider).clientWidth;
    // const sliderHeight = (_get(this.slider, 'innerSlider.list') || _get(this.slider, 'webtoonSlider')).clientHeight;
    const clickTime = Date.now();
    if (!isZoomIn && cursorX < sliderWidth / 3) {
      // LEFT
      if (clickTime - this.lastClickTime < DOUBLE_TAP_SENSITIVITY) {
        clearTimeout(this.changeSlideTimeout);
        this.startPinch(currIndex);
        // this.handleChangeSlide('FIRST');
      } else {
        this.changeSlideTimeout = setTimeout(() => {
          if (readingDirection === READING_TOP_TO_BOTTOM) {
            this.slider.runBigScroll(SCROLL_DIRECTION_UP);
          } else {
            this.slides[currIndex].runBigScroll(
              readingDirection === READING_RIGHT_TO_LEFT ? SCROLL_DIRECTION_DOWN : SCROLL_DIRECTION_UP,
            );
          }
        }, DOUBLE_TAP_SENSITIVITY);
      }
    } else if (!isZoomIn && cursorX > (sliderWidth * 2) / 3) {
      // RIGHT
      if (clickTime - this.lastClickTime < DOUBLE_TAP_SENSITIVITY) {
        clearTimeout(this.changeSlideTimeout);
        this.startPinch(currIndex);
        // this.handleChangeSlide('LAST');
      } else {
        this.changeSlideTimeout = setTimeout(() => {
          if (readingDirection === READING_TOP_TO_BOTTOM) {
            this.slider.runBigScroll(SCROLL_DIRECTION_DOWN);
          } else {
            this.slides[currIndex].runBigScroll(
              readingDirection === READING_RIGHT_TO_LEFT ? SCROLL_DIRECTION_UP : SCROLL_DIRECTION_DOWN,
            );
          }
        }, DOUBLE_TAP_SENSITIVITY);
      }
    } else {
      // CENTER
      // eslint-disable-next-line no-lonely-if
      if (readingDirection !== READING_TOP_TO_BOTTOM && clickTime - this.lastClickTime < DOUBLE_TAP_SENSITIVITY) {
        if (isZoomIn) {
          this.resetPinch();
        } else {
          this.startPinch(currIndex);
        }
      } else if (!isZoomIn && !isMobile) {
        this.handleChangeMode();
        // this.handleClosePlayer();
      }
    }
    this.lastClickTime = clickTime;
  };

  swipeStart = e => {
    this.dragging = true;
    if (e.touches && e.touches.length === 2) {
      // handle pinch (zoom gesture) https://gist.github.com/iammerrick/c4bbac856222d65d3a11dad1c42bdcca
      const container = this.slider?.innerSlider?.list || this.slider?.webtoonSlider;
      if (container) {
        const { currIndex, pinch } = this.state;
        const imageInfo = this.slides[currIndex].getImageInfo();
        const isZoomIn = pinch && pinch.scale > PINCH_MIN_SCALE;
        if (!isZoomIn) {
          this.initialImageWidth = imageInfo.width;
          this.initialImageHeight = imageInfo.height;
        }
        const pointA = getPointFromTouch(e.touches[0], container);
        const pointB = getPointFromTouch(e.touches[1], container);
        this.lastDistance = getDistanceBetweenPoints(pointA, pointB);
      }
    }
    this.touchObject = {
      curX: e.touches ? e.touches[0].pageX : e.clientX,
      curY: e.touches ? e.touches[0].pageY : e.clientY,
    };
  };

  swipeMove = e => {
    const { readingDirection } = this.props;
    const { scrollTo, viewMode, pinch, currIndex } = this.state;
    const newTouchObject = {
      curX: e.touches ? e.touches[0].pageX : e.clientX,
      curY: e.touches ? e.touches[0].pageY : e.clientY,
      lastX: this.touchObject.curX,
      lastY: this.touchObject.curY,
    };

    const isWebtoon = readingDirection === READING_TOP_TO_BOTTOM;
    const isMutliTouch = e.touches && e.touches.length > 1;
    const { top } = this.slides[currIndex].getImageInfo();
    if (isMutliTouch && !isWebtoon) {
      // handle pinch (zoom gesture) https://gist.github.com/iammerrick/c4bbac856222d65d3a11dad1c42bdcca
      const container = this.slider?.innerSlider?.list || this.slider?.webtoonSlider;
      const pointA = getPointFromTouch(e.touches[0], container, top);
      const pointB = getPointFromTouch(e.touches[1], container, top);
      const distance = getDistanceBetweenPoints(pointA, pointB);
      const midpoint = getMidpoint(pointA, pointB);
      const newScale = between(
        PINCH_MIN_SCALE - PINCH_ADDITIONAL_LIMIT,
        PINCH_MAX_SCALE + PINCH_ADDITIONAL_LIMIT,
        pinch.scale * (distance / this.lastDistance),
      );

      this.zoom(newScale, midpoint);

      this.lastMidpoint = midpoint;
      this.lastDistance = distance;
      return;
    }
    // handle simple move on zoomed slide
    if (pinch && pinch.scale > PINCH_MIN_SCALE) {
      const nextX = pinch.x + inverse(newTouchObject.lastX - newTouchObject.curX) * TOUCH_MOVE_SENSITIVIY_X;
      const nextY = pinch.y + inverse(newTouchObject.lastY - newTouchObject.curY) * TOUCH_MOVE_SENSITIVIY_Y;
      if (this.updatePinch(nextX, nextY, pinch.scale)) {
        this.touchObject = newTouchObject;
      }
      return;
    }

    let newScrollTo = 0;
    if (viewMode === MODE_FITWIDTH) {
      const direction = getSwipeDirection(newTouchObject);
      if (direction === SWIPE_DIRECTION_UP || direction === SWIPE_DIRECTION_DOWN) {
        const delta = newTouchObject.curY - newTouchObject.lastY;
        if (!isWebtoon) {
          newScrollTo = scrollTo + delta;
        } else {
          // TODO: https://stackoverflow.com/questions/1810742/algorithm-to-implement-kinetic-scrolling
          // const elapsed = 0;
          // const v = (1000 * delta) / (1 + elapsed);
          // this.velocity = 0.8 * v + 0.2 * this.velocity;
          this.handleWheel({ deltaY: -delta * WEBTOON_SCROLL_SPEED_RATIO }, true);
        }
      }
    }
    this.touchObject = newTouchObject;
    if (!isWebtoon) {
      this.setState({
        scrollTo: newScrollTo,
      });
    }
  };

  swipeEnd = event => {
    const { readingDirection } = this.props;
    const { pinch } = this.state;
    // const { pinch, showLast, viewMode } = this.state;
    const isWebtoon = readingDirection === READING_TOP_TO_BOTTOM;
    const isZoomIn = pinch && pinch.scale > PINCH_MIN_SCALE;

    const direction = getSwipeDirection(this.touchObject);
    if (direction === SWIPE_DIRECTION_NONE) {
      this.handleClick(event);
    } else if (!isZoomIn && !isWebtoon) {
      if (direction === SWIPE_DIRECTION_RIGHT) {
        this.handleChangeSlide('PREVIOUS');
      } else if (direction === SWIPE_DIRECTION_LEFT) {
        this.handleChangeSlide('NEXT');
      }
      // } else if (!isZoomIn && isWebtoon && !showLast && viewMode !== MODE_FITCOMMENTS) {
      //   if (direction === SWIPE_DIRECTION_UP) {
      //     this.slider.runBigScroll(SCROLL_DIRECTION_DOWN);
      //   } else if (direction === SWIPE_DIRECTION_DOWN) {
      //     this.slider.runBigScroll(SCROLL_DIRECTION_UP);
      //   }
    }

    // this.resetPinch();
    this.handleShowMenu();
    this.dragging = false;
  };

  zoom = (scale, midpoint) => {
    const { currIndex } = this.state;
    const imageInfo = this.slides[currIndex].getImageInfo();

    const nextX = imageInfo.width / 2 + inverse(midpoint.x) * scale;
    const nextY = imageInfo.height / 2 + inverse(midpoint.y) * scale;

    this.updatePinch(nextX, nextY, scale);
  };

  updatePinch = (x, y, scale) => {
    const pinchTime = Date.now();
    if (pinchTime - this.lastPinch < PINCH_SENSITIVITY) {
      return false;
    }
    this.lastPinch = pinchTime;
    const maxX = -this.initialImageWidth * scale + this.initialImageWidth;
    const maxY = -this.initialImageHeight * scale + this.initialImageHeight;
    let newX = x > 0 ? 0 : x;
    let newY = y > 0 ? 0 : y;
    newX = newX < maxX ? maxX : newX;
    newY = newY < maxY ? maxY : newY;

    // console.log('updatePinch');
    if (scale < PINCH_MIN_SCALE) {
      this.resetPinch();
      return true;
    }
    this.setState({
      pinch: {
        x: newX,
        y: newY,
        scale,
      },
    });
    return true;
  };

  startPinch = currIndex => {
    const imageInfo = this.slides[currIndex].getImageInfo();
    this.initialImageWidth = imageInfo.width;
    this.initialImageHeight = imageInfo.height;
    const center = getImageCenter(this.initialImageWidth, this.initialImageHeight, PINCH_MAX_SCALE);
    this.updatePinch(center.x, center.y, PINCH_MAX_SCALE);
  };

  resetPinch = () => {
    this.setState({
      pinch: DEFAULT_PINCH,
    });
  };

  /* ******************************
   * USER MAIN ACTIONS
   ****************************** */

  handleChangeMode = () => {
    const { readingDirection } = this.props;
    const { viewMode } = this.state;
    if (readingDirection === READING_TOP_TO_BOTTOM) {
      // webtoon
      if (viewMode === MODE_FITCOMMENTS) {
        this.setState({ viewMode: MODE_FITWIDTH, commentsFocused: false });
      }
      return;
    }
    this.setState({ viewMode: VIEWMODE_SEQ[viewMode], commentsFocused: false });
  };

  handleShowControls = () => {
    this.setState(state => ({ showControls: !state.showControls }));
  };

  handleShowLast = () => {
    const { flowName, artworkId, getSuggestionsAction } = this.props;
    const { showLast } = this.state;
    if (!showLast) {
      getSuggestionsAction(artworkId);
      TagManager.dataLayer({
        dataLayer: {
          action: 'Show last modal',
          label: flowName,
        },
        dataLayerName: 'PlayerDataLayer',
      });
    }
    this.setState(state => ({ showLast: !state.showLast }));
  };

  handleNotInterested = id => {
    const { markNotInterestedAction } = this.props;
    markNotInterestedAction(id);
  };

  handleShowOpening = () => {
    const { firstFlow, readingDirection } = this.props;
    if ((readingDirection === READING_RIGHT_TO_LEFT || readingDirection === READING_TOP_TO_BOTTOM) && firstFlow) {
      this.readTimer = setTimeout(() => this.setState({ showOpening: false }), OPENING_INFO_TIMEOUT);
      this.setState({ showOpening: readingDirection });
    }
  };

  handleShowComments = () => {
    const { flowName } = this.props;
    const { viewMode } = this.state;
    if (viewMode === MODE_FITCOMMENTS) {
      this.handleCloseComments();
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: 'Show comments',
          label: flowName,
        },
        dataLayerName: 'PageDataLayer',
      });
      this.setState({
        viewMode: MODE_FITCOMMENTS,
      });
    }
  };

  handleVisitorComment = () => {
    const { flowName } = this.props;
    // le tag manager en dessous ne semble pas fonctionner
    TagManager.dataLayer({
      dataLayer: {
        event: 'Show visitor comment',
        label: flowName,
      },
      dataLayerName: 'PageDataLayer',
    });
    this.setState({ showVisitorComment: true });
  };

  handleCloseComments = () => {
    this.handleChangeMode();
  };

  handleCloseTutorial = tutorialId => {
    const { acceptTutorialAction } = this.props;
    if (tutorialId) {
      acceptTutorialAction(tutorialId);
    }
    this.setState({ showTutorial: false }, this.handleShowOpening);
  };

  computeCommentsPosition = () => {
    const { files } = this.props;
    const { currIndex } = this.state;
    const commentsNode = document.getElementById(`Player-comments-ghost-${files[currIndex].file.id}`);
    if (commentsNode) {
      return {
        left: commentsNode.offsetLeft, // - currIndex * _get(this.slider, 'innerSlider.state.slideWidth'),
        // top: commentsNode.offsetTop,
        // top: `calc((100vh - 1rem - ${commentsNode.clientHeight}px) / 2)`,
        // height: commentsNode.clientHeight,
      };
    }
    return { left: -4000, top: 0, height: 0 };
  };

  handleChangeSlide = (move, position) => {
    const { currIndex } = this.state;
    const { files, readingDirection } = this.props;
    let goIndex = -1;
    switch (move) {
      case 'NEXT':
        if (currIndex < files.length - 1) {
          goIndex = currIndex + 1;
        } else if (readingDirection !== READING_RIGHT_TO_LEFT) {
          this.handleShowLast();
        }
        break;
      case 'PREVIOUS':
        if (currIndex > 0) {
          goIndex = currIndex - 1;
        } else if (readingDirection === READING_RIGHT_TO_LEFT) {
          this.handleShowLast();
        }
        break;
      case 'FIRST':
        goIndex = 0;
        break;
      case 'LAST':
        goIndex = files.length - 1;
        break;
      case 'PAGE':
        goIndex = position - 1;
        break;
      default:
        break;
    }
    if (goIndex >= 0 && this.slider) {
      // console.log(move, position, goIndex);
      this.slideInitPosition = position;
      this.slider.slickGoTo(goIndex);
      this.setState({
        commentsFocused: false,
        pinch: {
          x: 0,
          y: 0,
          scale: PINCH_MIN_SCALE,
        },
      });
      return true;
    }
    return false;
  };

  handleShowMenu = () => {
    const { showMenu } = this.state;
    if (!showMenu) {
      this.callbackTimers.push(
        setTimeout(() => {
          this.setState({ showMenu: true });
          clearTimeout(this.menuStateTime);
          this.menuStateTime = setTimeout(() => this.setState({ showMenu: false }), MENU_TIMER);
        }),
      );
    }
  };

  handleClosePlayer = () => {
    const { artworkUrl, onClose, history } = this.props;
    if (onClose) {
      onClose(); // call for modal
    } else {
      history.push(artworkUrl);
    }
  };

  handleSaveFavorite = () => {
    const { artworkId, flowName, userProfile, addFollowedArtworkAction } = this.props;
    TagManager.dataLayer({
      dataLayer: {
        event: 'Save as favorite from player',
        label: flowName,
      },
      dataLayerName: 'PageDataLayer',
    });
    addFollowedArtworkAction({
      userId: userProfile.id,
      artworkId,
    });
  };

  /* ******************************
   * SLIDERS WATCHER
   ****************************** */

  markAsRead = index => {
    const {
      type,
      id,
      artworkId,
      files,
      userProfile,
      favoriteArtworks,
      markAsReadFileArtworkAction,
      markAsReadFilePackAction,
      getFileFeedbacksAction,
    } = this.props;
    // if (files[index].isRead === false) {
    const currentFile = files[index];
    getFileFeedbacksAction(currentFile.file.id);
    this.readTimer = setTimeout(() => {
      if (type === 'flow')
        markAsReadFileArtworkAction(
          currentFile,
          parseInt(id, 10),
          artworkUtils.hasAffinity(favoriteArtworks, artworkId),
          userProfile,
        );
      if (type === 'pack')
        markAsReadFilePackAction(
          currentFile,
          parseInt(id, 10),
          artworkId,
          artworkUtils.hasAffinity(favoriteArtworks, artworkId),
          userProfile,
        );
    }, MARK_AS_READ_TIMEOUT);
  };

  /*
   * When first page is loading
   */
  onInit = () => {
    const { files, readingDirection, onRefresh, firstFlow, acceptedTutorials, getFileCommentsAction } = this.props;
    const { currIndex } = this.state;

    // check loading error of current image
    this.imageLoaded.push(currIndex);
    loadImage(files[currIndex].file.contentUrl)
      .then(null, () => {
        // this.handleChangeSlide(readingDirection === READING_RIGHT_TO_LEFT ? 'PREVIOUS' : 'NEXT');
      })
      .catch(e => console.log('cannot load', e));

    // begin async preload of next slide
    let nextSlideIndex = null;
    if ((readingDirection === READING_LEFT_TO_RIGHT || currIndex === 0) && currIndex + 1 < files.length) {
      nextSlideIndex = currIndex + 1;
    } else if ((readingDirection === READING_RIGHT_TO_LEFT || currIndex === files.length - 1) && currIndex > 0) {
      nextSlideIndex = currIndex - 1;
    }
    if (nextSlideIndex) {
      this.callbackTimers.push(
        setTimeout(() => {
          this.imageLoaded.push(nextSlideIndex);
          loadImage(files[nextSlideIndex].file.contentUrl, null, () => {
            // next slide has error and drm
            if (artworkUtils.isUnlocked(files[nextSlideIndex].file)) {
              onRefresh(currIndex);
            }
          }).catch(e => console.log('cannot load', e));
        }),
      );
    }
    this.markAsRead(currIndex);
    getFileCommentsAction(files[currIndex].file.id);
    if (!firstFlow) {
      return;
    }
    const isTutorialAccepted = acceptedTutorials?.includes(tutorialControlsId);
    if (isTutorialAccepted) {
      this.handleShowOpening();
      return;
    }
    this.setState({ showTutorial: true });
  };

  /*
   * Beforing changing page occur
   * setState bug : https://github.com/akiran/react-slick/issues/1214
   */
  beforeChange = (current, next) => {
    const { files, readingDirection, onRefresh } = this.props;
    const { viewMode } = this.state;
    clearTimeout(this.readTimer);
    this.callbackTimers.push(
      setTimeout(() => {
        // track current file and index
        this.setState({
          currIndex: next,
          slideUpdated: false,
          imgError: false,
        });
      }),
    );

    const isWebToon = readingDirection === READING_TOP_TO_BOTTOM;
    if (!isWebToon && viewMode !== MODE_FITWIDTH) {
      const prevSlideElement = this.slider.innerSlider.list.querySelector(`[data-index="${current}"]`);
      const nextSlideElement = this.slider.innerSlider.list.querySelector(`[data-index="${next}"]`);
      setTimeout(() => {
        const animDirection = readingDirection === READING_LEFT_TO_RIGHT ? 'ltr' : 'rtl';
        const isNext =
          (readingDirection !== READING_RIGHT_TO_LEFT && current < next) ||
          (readingDirection === READING_RIGHT_TO_LEFT && current > next);
        if (SLIDE_ANIMATED) {
          if (isNext) {
            prevSlideElement.classList.add('slideAnimated', 'slideExitNext', animDirection);
            prevSlideElement.classList.remove('slideEnterNext');
            nextSlideElement.classList.remove('slideExitNext');
            nextSlideElement.classList.add('slideAnimated', 'slideEnterNext', animDirection);
          } else {
            prevSlideElement.classList.add('slideAnimated', 'slideEnterPrevious', animDirection);
            prevSlideElement.classList.remove('slideExitPrevious');
            nextSlideElement.classList.remove('slideEnterPrevious');
            nextSlideElement.classList.add('slideAnimated', 'slideExitPrevious', animDirection);
          }
        }
      });
    }

    // begin async load of next slide
    let nextSlideIndex = null;
    if (readingDirection === READING_LEFT_TO_RIGHT && next + 1 < files.length) {
      nextSlideIndex = next + 1;
    } else if (readingDirection === READING_RIGHT_TO_LEFT && next > 0) {
      nextSlideIndex = next - 1;
    }
    if (!isWebToon && nextSlideIndex) {
      this.callbackTimers.push(
        setTimeout(() => {
          this.imageLoaded.push(nextSlideIndex);
          loadImage(files[nextSlideIndex].file.contentUrl, null, () => {
            console.log('failed to next image', files[nextSlideIndex].file.contentUrl);
            // next slide has error and drm
            if (artworkUtils.isUnlocked(files[nextSlideIndex].file)) {
              console.log('refresh !', next);
              onRefresh(next);
            }
          }).catch(e => console.log('cannot load', e));
        }),
      );
    }

    if (!isWebToon) {
      // check loading error for new slide
      this.imageLoaded.push(next);
      loadImage(files[next].file.contentUrl)
        .then(null, () => {
          console.log('failed to load current image', files[next].file.contentUrl);
          if (artworkUtils.isUnlocked(files[next].file)) {
            console.log('refresh !', next);
            onRefresh(next);
          }
          // } else if (next > current) {
          //   this.callbackTimers.push(setTimeout(() => this.handleChangeSlide('NEXT'), 500));
          // } else {
          //   this.callbackTimers.push(setTimeout(() => this.handleChangeSlide('PREVIOUS'), 500));
          // }
        })
        .catch(e => console.log('cannot load', e));
    }
  };

  afterChange = index => {
    const { type, id, files, artworkUniqueName, getFileCommentsAction } = this.props;
    const filePath = changePlayerUrl(type, id, files[index].file.id, artworkUniqueName);
    this.markAsRead(index);
    getFileCommentsAction(files[index].file.id);
    this.setState({
      slideUpdated: true,
      showControls: false,
      showLast: false,
      commentsPosition: this.computeCommentsPosition(),
      filePath,
    });
  };

  render() {
    const {
      viewMode,
      showMenu,
      currIndex,
      filePath,
      scrollTo,
      pinch,
      slideUpdated,
      showLast,
      showControls,
      showOpening,
      showVisitorComment,
      showTutorial,
      // commentsPosition,
      loginModalMode,
      imgError,
      overImage,
      debug,
    } = this.state;
    const {
      type,
      files,
      readingDirection,
      userProfile,
      userCart,
      artworkId,
      artworkUrl,
      artworkAuthor,
      artworkName,
      artworkCover,
      packId,
      packCover,
      flowName,
      price,
      favoriteArtworks,
      posts,
      nextFlow,
      suggestions,
      onNextFlow,
    } = this.props;
    const isWebToon = readingDirection === READING_TOP_TO_BOTTOM;
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      speed: 500, // need change on transition-duration (css)
      fade: viewMode !== MODE_FITBOX && !isWebToon,
      initialSlide: currIndex,
      lazyLoad: true,
      vertical: isWebToon,
      swipe: false,
      touchMove: false,
      accessibility: false,
      arrows: false,
      // useCSS: false,
      // useTransform: false,
      onInit: this.onInit,
      beforeChange: this.beforeChange,
      afterChange: this.afterChange,
    };
    const isZoomIn = pinch && pinch.scale > PINCH_MIN_SCALE;
    const isFavoriteArtwork = !!favoriteArtworks?.find(a => a.id === artworkId);
    const isModalOpen = showLast || showControls || showOpening || showVisitorComment || showTutorial;
    const isFileLocked = !files[currIndex].file.isAccessible;
    return (
      <>
        {debug && (
          <div className="Player-debugger">
            <ul>
              <li>type: {type}</li>
              <li>
                current: i{currIndex} #{files[currIndex].file.id} ({isFileLocked ? 'locked' : 'unlocked'})
              </li>
              {/* <li>files: {files.map(file => file.file.id).join(',')})</li> */}
              <li>
                viewMode: {viewMode}, readingDirection: {readingDirection}
              </li>
              <li>Loaded files: {this.imageLoaded.join(' ')}</li>
              <li>
                Events:{' '}
                {JSON.stringify(
                  this.debugEvent.reduce((acc, curr) => {
                    if (!acc[curr.eventType]) {
                      acc[curr.eventType] = [];
                    }
                    if (!acc[curr.eventType].includes(curr.value)) {
                      acc[curr.eventType].push(curr.value);
                    }
                    return acc;
                  }, {}),
                )}
              </li>
            </ul>
          </div>
        )}
        {!isModalOpen && (
          <Header
            show={showMenu}
            closeHandler={this.handleClosePlayer}
            index={currIndex}
            files={files}
            readingDirection={readingDirection}
            changeSlideHandler={this.handleChangeSlide}
            type={type}
            artworkId={artworkId}
            artworkUrl={artworkUrl}
            artworkName={artworkName}
            artworkCover={artworkCover}
            packCover={packCover}
            flowName={flowName}
          />
        )}
        <div
          className={cx(['Player-Wrapper'], { zoom: isZoomIn, overImage })}
          onTouchStart={this.swipeStart}
          onTouchMove={this.swipeMove}
          onTouchEnd={this.swipeEnd}
          onTouchCancel={this.dragging ? this.swipeEnd : null}
        >
          <Arrows
            currIndex={currIndex}
            files={files}
            showMenu={showMenu}
            viewMode={viewMode}
            readingDirection={readingDirection}
          />
          {isWebToon && (
            <PlayerWebtoonSlider
              ref={c => {
                this.slider = c;
              }}
              slides={this.slides}
              className={`Player-Slider ${viewMode}${isWebToon ? ' webtoon' : ''}`}
              initialSlide={currIndex}
              onLastFile={this.handleShowLast}
              onInit={this.onInit}
              beforeChange={this.beforeChange}
              afterChange={this.afterChange}
              debug
              debugEvent={(eventType, value) => this.debugEvent.push({ eventType, value })}
            >
              {files.map((file, index) => (
                <PlayerSlide
                  ref={c => {
                    this.slides[index] = c;
                  }}
                  key={file.file.id}
                  file={file}
                  lazy
                  index={index}
                  viewMode={viewMode}
                  slideUpdated={slideUpdated}
                  changeSlideHandler={this.handleChangeSlide}
                  changeModeHandler={this.handleChangeMode}
                  closeHandler={this.handleClosePlayer}
                  readingDirection={readingDirection}
                  imgError={imgError}
                  onImgError={value => this.setState({ imgError: value })}
                  debug={debug}
                />
              ))}
            </PlayerWebtoonSlider>
          )}
          {!isWebToon && (
            <Slider
              ref={c => {
                this.slider = c;
              }}
              {...settings}
              className={`Player-Slider ${viewMode} h-100`}
              tabIndex="0"
            >
              {files.map((file, index) => (
                <PlayerSlide
                  ref={c => {
                    this.slides[index] = c;
                  }}
                  key={file.file.id}
                  file={file}
                  index={index}
                  viewMode={viewMode}
                  scroll={scrollTo}
                  pinch={index === currIndex ? pinch : DEFAULT_PINCH}
                  slideUpdated={slideUpdated}
                  changeSlideHandler={this.handleChangeSlide}
                  changeModeHandler={this.handleChangeMode}
                  closeHandler={this.handleClosePlayer}
                  readingDirection={readingDirection}
                  imgError={imgError}
                  onImgError={value => this.setState({ imgError: value })}
                  onMouseOver={() => this.setState({ overImage: true })}
                  onMouseOut={() => this.setState({ overImage: false })}
                  debug={debug}
                />
              ))}
            </Slider>
          )}
        </div>
        <UnlockAction
          type={type}
          packId={packId}
          artworkId={artworkId}
          price={price}
          userCart={userCart}
          isLocked={isFileLocked}
        />
        {!isModalOpen && (
          <>
            <FeedbackPanel
              file={files[currIndex]}
              viewMode={viewMode}
              nbComments={posts ? posts.length : 0}
              userProfile={userProfile}
              onShowComments={this.handleShowComments}
            />
            <Footer
              fileName={files[currIndex].file.name}
              viewMode={viewMode}
              show={showMenu}
              type={type}
              artworkId={artworkId}
              artworkUrl={artworkUrl}
              artworkName={artworkName}
              artworkCover={artworkCover}
              packCover={packCover}
              flowName={flowName}
              changeModeHandler={this.handleChangeMode}
              showControlsHandler={this.handleShowControls}
            />
          </>
        )}
        {imgError && (
          <LoadingButton
            className="Player-imgerror px-5 btn-pill"
            variant="secondary"
            label="Reload now"
            type="button"
            isFetching={imgError === 'loading'}
            onClick={() => {
              this.setState({ imgError: 'loading' });
            }}
          />
        )}
        {/* CONTROLS */}
        {!!showControls && <Controls show={showControls} onClose={this.handleShowControls} />}
        {/* OPENING INFO */}
        {!!showOpening && (
          <OpeningInfo show={!!showOpening} type={showOpening} onClose={() => this.setState({ showOpening: false })} />
        )}
        {/* LAST INFO */}
        {showLast && (
          <LastInfo
            show={showLast}
            userProfile={userProfile}
            artworkIsFavorite={isFavoriteArtwork}
            artworkName={artworkName}
            artworkAuthor={artworkAuthor}
            artworkCover={artworkCover}
            nextFlow={nextFlow}
            suggestions={suggestions}
            onClose={() => this.setState({ showLast: false })}
            onGoFirst={() => {
              this.handleChangeSlide(readingDirection === READING_RIGHT_TO_LEFT ? 'LAST' : 'FIRST');
              this.setState({ showLast: false });
            }}
            onNextFlow={onNextFlow}
            onSaveFavorite={this.handleSaveFavorite}
            onClosePlayer={this.handleClosePlayer}
            onNotInterested={this.handleNotInterested}
            onSignup={() => this.setState({ showLast: false, loginModalMode: 'register' })}
          />
        )}
        {/* VISITOR ADD COMMENT */}
        {showVisitorComment && (
          <VisitorComment
            show={showLast}
            onSignup={() => this.setState({ showVisitorComment: false, loginModalMode: 'register' })}
            onClose={() => this.setState({ showVisitorComment: false })}
          />
        )}
        {/* COMMENTS */}
        {viewMode === MODE_FITCOMMENTS && (
          <div
            className={`Player-Info Player-Info-comments ${
              viewMode === MODE_FITCOMMENTS && !isModalOpen ? 'flex' : 'hide'
            }`}
            onClick={() => this.setState({ commentsFocused: true })}
            role="presentation"
            // style={{
            //   left: commentsPosition.left,
            //   marginTop: commentsPosition.top,
            //   marginBottom: commentsPosition.top,
            //   height: commentsPosition.height,
            // }}
          >
            <PlayerComments
              file={files[currIndex]}
              flowName={flowName}
              artworkAuthor={artworkAuthor}
              filePath={filePath}
              handleCloseComments={this.handleCloseComments}
              onVisitorComment={this.handleVisitorComment}
            />
          </div>
        )}
        {showTutorial && (
          <TutorialControls
            show={showTutorial}
            readingDirection={readingDirection}
            onClose={this.handleCloseTutorial}
          />
        )}
        {loginModalMode && (
          <LoginModal mode={loginModalMode} onClose={() => this.setState({ loginModalMode: false })} />
        )}
        <div
          className={`Player-Info-Background${isModalOpen ? ' show' : ' hide'}${
            viewMode === MODE_FITCOMMENTS ? ' m-show' : ''
          }`}
          onClick={() => {
            if (showTutorial) {
              this.handleCloseTutorial();
            }
            this.setState({
              showLast: false,
              showControls: false,
              showOpening: false,
              showVisitorComment: false,
              showTutorial: false,
            });
          }}
          role="presentation"
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  posts: state.comment.fileComments,
  suggestions: state.artwork.suggestions,
  userProfile: state.user.privateProfile,
  userCart: state.user.cart,
  favoriteArtworks: state.user.favoriteArtworks,
  acceptedTutorials: state.user.acceptedTutorials,
});

const mapDispatchToProps = dispatch => ({
  getFileCommentsAction: id => dispatch(getFileComments(id)),
  getFileFeedbacksAction: id => dispatch(getFileFeedbacks(id)),
  markAsReadFileArtworkAction: (file, artworkId, hasAffinity, user) =>
    dispatch(markAsReadFileArtwork(file, artworkId, hasAffinity, user)),
  markAsReadFilePackAction: (file, packId, artworkId, hasAffinity, user) =>
    dispatch(markAsReadFilePack(file, packId, artworkId, hasAffinity, user)),
  addFollowedArtworkAction: params => dispatch(addFollowedArtwork(params)),
  acceptTutorialAction: tutorialId => dispatch(acceptTutorial(tutorialId)),
  getSuggestionsAction: id => dispatch(getSuggestions(id)),
  markNotInterestedAction: id => dispatch(markNotInterested(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withMobileMediaQuery(memo(PlayerSlider))));
