import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

type Props = {
  show: Boolean,
  onClose: Function,
};

const Controls = ({ show, onClose }: Props) => {
  return (
    <div className={`Player-Info ${show ? 'show' : 'hide'}`} onClick={onClose} role="presentation">
      <div className="d-flex h-100">
        <Row className="Player-Info-Wrapper d-flex h-100 align-content-center flex-column flex-nowrap flex-md-row flex-md-wrap mx-auto">
          <Col className="d-inline-flex flex-column text-center">
            <img className="mt-auto" src="/assets/images/player/fit_modes.svg" alt="fit_modes" />
            <img src="/assets/images/player/tactile_commands.svg" alt="tactile_commands" />
          </Col>
          <Col className="d-inline-flex flex-column text-center">
            <img src="/assets/images/player/keyboard_commands.svg" alt="keyboard_commands" />
            <img className="mb-auto" src="/assets/images/player/mouse_commands.svg" alt="mouse_commands" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default React.memo(Controls);
