import React from 'react';

import { useMediaQuery } from 'react-responsive';

import { MEDIA_LG } from 'constants/Constants';

export const withMobileMediaQuery = Component => props => {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} isMobile={isMobile} />;
};
