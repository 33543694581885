import cx from 'classnames';

import React, { memo, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Badge from 'react-bootstrap/Badge';

import { CURRENCY } from 'constants/Constants';

import cartTools from 'tools/cart';

import { showNotification } from 'actions/NotificationsActions';
import { placeQuote, showCart } from 'actions/UserActions';

import PaymentSVGs from 'svgs/payment';
import PlayerSVGs from 'svgs/player';

import styles from './UnlockAction.module.scss';

interface Props {
  type: 'pack' | 'flow';
  packId: number;
  artworkId: number;
  price: number;
  userCart: any;
  isLocked?: boolean;
  isLoading?: boolean;
}

export default memo(function UnlockAction({ type, packId, artworkId, price, userCart, isLocked, isLoading }: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cartSize = cartTools.getCartSize(userCart);

  const handleShowCart = () => {
    dispatch(showCart());
  };

  const quote = useMemo(() => cartTools.getQuoteFromCart(userCart), [userCart]);
  const isAlreadyInCart = useMemo(
    () =>
      cartTools.isInQuote(quote, {
        artworkId: type === 'flow' ? artworkId : undefined,
        packId: type === 'pack' ? packId : undefined,
      }),
    [quote, type, artworkId, packId],
  );

  const unlockFiles = () => {
    if (isAlreadyInCart) {
      dispatch(
        showNotification({
          level: 'info',
          title: 'Already in your cart.',
        }),
      );
      handleShowCart();
      return;
    }
    if (type === 'pack') {
      quote.packs.push(packId);
      dispatch(placeQuote(quote, { packId }, { id: artworkId }));
    } else if (type === 'flow') {
      quote.artworks.push(artworkId);
      dispatch(placeQuote(quote, { artworkId }, { id: artworkId }));
    }
    handleShowCart();
  };

  return (
    <div className={cx(styles.UnlockAction, 'fadeIn', 'delay-2s', { animated: isLoading })}>
      {cartSize > 0 && (
        <button className={cx(styles.cart, 'animated fadeIn')} onClick={handleShowCart} type="button">
          <div className={cx(styles.icon, { animated: true, fadeIn: true })}>
            <PaymentSVGs.Cart width="30" height="30" fill="#fff" className={cx({ animated: true, fadeIn: true })} />
            <Badge className={cx(styles.badge, { animated: true, fadeIn: true })}>{cartSize}</Badge>
          </div>
          <div className={styles.text}>{t('menu:see-my-cart')}</div>
        </button>
      )}
      {isLocked && (
        <button className={cx(styles.unlock, 'animated fadeIn')} onClick={unlockFiles} type="button">
          <PlayerSVGs.Unlock width="34" height="34" className={styles.icon} />
          <div>
            <div className={styles.title}>UNLOCK ALL PAGES</div>
            <div className={styles.caption}>
              {type === 'pack' && `${price} ${CURRENCY}`}
              {type === 'flow' && t('flow:subscription-price', { price, CURRENCY })} {t('flow:vat')}
            </div>
          </div>
        </button>
      )}
    </div>
  );
});
