import React from 'react';

import { useMediaQuery } from 'react-responsive';

import { MEDIA_LG } from 'constants/Constants';

import { READING_RIGHT_TO_LEFT, READING_TOP_TO_BOTTOM } from '../../Player-utils';

type Props = {
  show: Boolean,
  type: String,
  onClose: Function,
};

const OpeningInfo = ({ show, type, onClose }: Props) => {
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  return (
    <div className={`Player-Info-opening ${show ? 'show' : 'hide'}`} role="presentation" onClick={onClose}>
      <div className="d-flex h-100">
        <div className="Player-Info-opening-Wrapper">
          {type === READING_RIGHT_TO_LEFT && (
            <img className="mx-auto" src="/assets/images/player/manga-message.svg" alt="right-to-left" />
          )}
          {type === READING_TOP_TO_BOTTOM && (
            <img
              className="mx-auto"
              src={`/assets/images/player/webtoon-message${isMobile ? '-mobile' : ''}.svg`}
              alt="webtoon"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(OpeningInfo);
