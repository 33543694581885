import React from 'react';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import Button from 'react-bootstrap/Button';

import { MEDIA_LG } from 'constants/Constants';

import { READING_TOP_TO_BOTTOM } from '../../Player-utils';

type Props = {
  show: Boolean,
  readingDirection: string,
  onClose: Function,
};

export const tutorialId = 'tc1';

const ControlsTutorial = ({ show, readingDirection, onClose }: Props) => {
  const { t } = useTranslation('player');
  const isMobile = !useMediaQuery({
    minWidth: MEDIA_LG,
  });
  let src = '/assets/images/player/tuto_';
  if (isMobile && readingDirection === READING_TOP_TO_BOTTOM) {
    src += 'webtoon_';
  }
  src += isMobile ? 'touchscreen.svg' : 'desktop.svg';
  return (
    <div className={`Player-comment-visitor Player-modal ${show ? 'show' : 'hide'}`}>
      <div className="Player-comment-visitor-Wrapper">
        <img src={src} alt="tuto controls" />
        <Button variant="secondary" size="sm" className="btn-pill mt-4" onClick={() => onClose(tutorialId)}>
          {t('tuto-ok-button')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ControlsTutorial);
