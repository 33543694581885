import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import _isEmpty from 'lodash/isEmpty';
import _reverse from 'lodash/reverse';
import _uniq from 'lodash/uniq';

import Modal from 'react-bootstrap/Modal';

import acl from 'tools/acl';
import artworkUtils from 'tools/artwork';

import { getArtworkById } from 'actions/ArtworkActions';
import { getPackById, getPackFiles } from 'actions/PackActions';
import { hidePlayer } from 'actions/PlayerActions';
import { getPrivateProfile, getFavoriteArtworks } from 'actions/UserActions';

import ArtworkDisclaimer from 'components/Artwork/ArtworkDisclaimer';
import Player from 'components/Player';

import './PlayerModal.scss';

type Props = {
  playerParams: Object,
  artwork: Object,
  noDisclaimer: Boolean,
  onClose(): void,
};
const PlayerModal = ({ playerParams, artwork, noDisclaimer, onClose }: Props) => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(true);
  const [accepted, setAccepted] = useState(!!noDisclaimer);

  // store selectors
  const userProfile = useSelector(state => state.user.privateProfile);
  const acceptedArtworks = useSelector(state => state.user.acceptedArtworks);
  const userHistory = useSelector(state => state.user.history);
  // actions
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (showModal) {
  /* the tagManager below triggers too often !!!!
      TagManager.dataLayer({
        dataLayer: {
          event: 'Show Player',
          label: JSON.stringify(playerParams),
        },
        dataLayerName: 'PageDataLayer',
      }); */
  //   }
  // }, [showModal, playerParams]);

  const showDisclaimer = artworkUtils.isDisclaimerNeeded(userProfile, artwork, acceptedArtworks, accepted);

  const handleClose = () => {
    // const { scrollPosition } = this.state;
    if (acl.isConnected(userProfile)) {
      dispatch(getPrivateProfile());
      dispatch(getFavoriteArtworks());
    }
    if (playerParams.type === 'flow') {
      dispatch(getArtworkById(playerParams.id, true));
    }
    if (playerParams.type === 'pack') {
      if (playerParams.artworkId) {
        dispatch(getArtworkById(playerParams.artworkId, true));
      }
      dispatch(getPackById(playerParams.id));
      dispatch(getPackFiles(playerParams.id));
    }
    setShowModal(false);
    setAccepted(false);
    // helpscout.show();
    // setTimeout(() => window.scrollTo(0, scrollPosition), 250);
    if (!_isEmpty(userHistory) && userHistory.length > 1) {
      const lastLocations = _reverse(_uniq(userHistory.filter(l => l !== location.pathname)));
      if (_isEmpty(lastLocations)) {
        // eslint-disable-next-line no-restricted-globals
        history.pushState(null, null, userHistory[1]);
      } else {
        // eslint-disable-next-line no-restricted-globals
        lastLocations.forEach(l => history.pushState(null, null, l));
      }
    }
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, null, location.pathname);
    if (onClose) onClose();
    dispatch(hidePlayer());
  };

  const handleAcceptDisclaimer = () => {
    setAccepted(true);
  };

  const handleCloseDisclaimer = () => {
    setShowModal(false);
    // helpscout.show();
  };

  if (showDisclaimer && showModal) {
    return (
      <ArtworkDisclaimer
        artwork={artwork}
        onAccept={handleAcceptDisclaimer}
        onClose={handleCloseDisclaimer}
        userProfile={userProfile}
      />
    );
  }

  return (
    <Modal show={showModal} onHide={handleClose} dialogClassName="Player-Modal">
      <Modal.Body>
        <Player
          type={playerParams.type}
          id={playerParams.id}
          fileId={playerParams.fileId}
          sortNumber={playerParams.sortNumber}
          onClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};

export default PlayerModal;
