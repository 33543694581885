import loadable from '@loadable/component';

import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';

import { THEME_DARK } from 'constants/Constants';

import acl from 'tools/acl';
import cartTools from 'tools/cart';
import { useTheme } from 'tools/theme';

import { placeQuote, placeOrder } from 'actions/UserActions';

import Cart from 'components/Cart';
import { LoadingSmall } from 'components/Loading';
import ForgotForm from 'components/Login/Form/ForgotForm';
import LoginForm from 'components/Login/Form/LoginForm';
import RegisterForm from 'components/Login/Form/RegisterForm';

import PaymentSVGs from 'svgs/payment';

const InputCreditCard = loadable(() => import('components/Form/InputCreditCard'), { fallback: <LoadingSmall /> });

interface Props {
  userProfile: Object;
  cart: Object;
  artworkId: number;
  isPlayer: boolean;
  onChangeTab(): void;
  onCancel(): void;
}

export default function CartContent({ userProfile, cart, artworkId, isPlayer, tab, onChangeTab, onClose }: Props) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const quoteStatus = useSelector(state => state.user?.status?.quote);

  const [error, setError] = useState(false);

  const handleAccept = () => {
    if (!userProfile.card) {
      setError(true);
      return;
    }
    const quote = cartTools.getQuoteFromCart(cart);
    dispatch(placeOrder(quote, artworkId, isPlayer));
    setError(false);
  };

  const handleRemove = purchase => {
    const quote = cartTools.getQuoteFromCart(cart);
    const params = {};
    if (purchase.pack) {
      quote.packs = quote.packs.filter(id => purchase.pack.id !== id);
      params.packId = purchase.pack.id;
    }
    if (purchase.artwork) {
      quote.artworks = quote.artworks.filter(id => purchase.artwork.id !== id);
      params.artworkId = purchase.artwork.id;
    }
    dispatch(placeQuote(quote, params));
  };

  const handleSuccess = () => {
    const quote = cartTools.getQuoteFromCart(cart);
    dispatch(placeQuote(quote));
    onChangeTab(false);
  };

  if (tab === 'login') {
    return (
      <>
        <div className="Logo">
          <img src={`/assets/images/flowfo${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
        </div>
        <LoginForm onChangeMode={onChangeTab} onSuccess={handleSuccess} />
      </>
    );
  }

  if (tab === 'register') {
    return (
      <>
        <div className="Logo">
          <img src={`/assets/images/flowfo${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
        </div>
        <RegisterForm onChangeMode={onChangeTab} onSuccess={handleSuccess} />
      </>
    );
  }

  if (tab === 'forgot') {
    return (
      <>
        <div className="Logo">
          <img src={`/assets/images/flowfo${theme === THEME_DARK ? '-darkmode' : ''}.svg`} alt="Flowfo" />
        </div>
        <ForgotForm onChangeMode={onChangeTab} onSuccess={handleSuccess} />
      </>
    );
  }

  return (
    <>
      <div className="CartModal-title h3 flowfont-title text-secondary">
        <div>
          <PaymentSVGs.Cart width="40" height="40" fill="var(--secondary)" />
        </div>
        <div>Cart</div>
      </div>
      <Cart cart={cart} handleRemove={handleRemove} />
      {acl.isConnected(userProfile) && quoteStatus !== 'fetching' && (
        <>
          <div className="CartModal-payment">
            <InputCreditCard label="Credit or debit card*" userProfile={userProfile} />
            {error && !userProfile.card && (
              <div className="CartModal-payment-error flowfont-medium text-danger">
                Thanks to enter your card number
              </div>
            )}
          </div>
          <div className="CartModal-accept">
            <Button
              className="flowfont-regular"
              id="FlowfoButtonProceedPayment"
              variant="primary rounded-pill"
              onClick={handleAccept}
              disabled={error || !userProfile.card}
            >
              Submit payment
            </Button>
          </div>
        </>
      )}
      {!acl.isConnected(userProfile) && quoteStatus !== 'fetching' && (
        <>
          <div className="mt-3">
            <div className="text-neutral flowfont-medium mt-3 mb-1">New user ?</div>
            <Button
              className="d-flex btn-primary btn-pill mx-auto px-10"
              variant="flowfont-regular"
              onClick={() => onChangeTab('register')}
            >
              Register
            </Button>
          </div>
          <div>
            <div className="text-neutral mt-3 mb-1 flowfont-medium">Already user ?</div>
            <Button
              className="d-flex btn-secondary btn-pill mx-auto px-5"
              variant="flowfont-regular"
              onClick={() => onChangeTab('login')}
            >
              Login
            </Button>
          </div>
        </>
      )}
      {quoteStatus === 'fetching' && (
        <div className="CartModal-loading">
          <LoadingSmall />
        </div>
      )}
      <div className="CartModal-exit text-center mt-3">
        <Button onClick={onClose} variant="link">
          Continue my journey
        </Button>
      </div>
      <div className="CartModal-complementInfo">
        *Our payment provider saves your encrypted CB.
        <br />
        Remove it anytime via your settings page.
        <br />
        As items are instantly available digital goods,
        <br />
        you expressly waive your right of retraction.
      </div>
    </>
  );
}
