import React from 'react';

import Button from 'react-bootstrap/Button';

import CommunSVGs from 'svgs/commun';

type Props = {
  show: Boolean,
  onClose(): void,
  onSignup(): void,
};

const VisitorComment = ({ show, onClose, onSignup }: Props) => {
  return (
    <div className={`Player-comment-visitor Player-modal ${show ? 'show' : 'hide'}`}>
      <div className="Player-comment-visitor-Wrapper">
        <div className="Player-comment-visitor-title">Want to comment?</div>
        <div>
          <div className="mb-3">It’s time to become a talking Flower :)</div>
          <img src="/assets/images/blah_blah.svg" alt="want to comment" />
          <Button variant="secondary" size="sm" className="btn-pill mt-3" onClick={onSignup}>
            Sign up (it’s free)
          </Button>
          <div className="mt-3">Many cool features wait for you!</div>
          <div className="mt-1">
            - support your favorite artworks <CommunSVGs.Favorites width="18" height="18" fill="#BBB" /> <br />
            - save your reading position <br />
            - get new pages notifications
            <br />- and... comment ;)
          </div>
        </div>
        <Button variant="outline-white" size="sm" className="btn-pill mt-3" onClick={onClose}>
          Resume
        </Button>
      </div>
    </div>
  );
};

export default React.memo(VisitorComment);
