import React, { memo } from 'react';

import { TagManager } from 'tools/analytics';

type Props = {
  cart: Object,
  email: string,
  userId: number,
};

const getDataLayerData = (order, userEmail, userId) => {
  const packs =
    order?.packAccesses?.map(packAccess => ({
      item_id: packAccess.pack.id,
      item_name: `${packAccess.pack.artwork.name} - ${packAccess.pack.name}`,
      item_brand: `${packAccess.pack.artwork.name} (Artwork id${packAccess.pack.artwork.id})`,
      item_artwork_id: packAccess.pack.artwork.id,
      item_category: packAccess.artworkAuthor.username,
      item_variant: 'Paying Flow',
      price: packAccess.netPrice,
      currency: packAccess.currency,
      quantity: 1,
    })) || [];
  const subscriptions =
    order?.subscriptionBillings?.map(subscription => ({
      item_id: `S - ${subscription.artwork.id}`,
      item_name: `${subscription.artwork.name}-${subscription.artwork.flowName}`,
      item_brand: `${subscription.artwork.name} (Artwork id${subscription.artwork.id})`,
      item_artwork_id: subscription.artwork.id,
      item_category: subscription.artworkAuthor.username,
      item_variant: 'Subscription Flow',
      price: subscription.netPrice,
      currency: subscription.currency,
      quantity: 1,
    })) || [];
  return {
    event: 'purchase',
    ecommerce: {
      transaction_id: order.id,
      value: order.netPrice,
      tax: order.vatPrice,
      currency: order.currency,
      buyer_country: order.country.label,
      items: [...packs, ...subscriptions],
    },
    userEmail,
    userId,
  };
};

function CartSuccess({ order, email, userId }: Props) {
  TagManager.dataLayer({
    dataLayer: getDataLayerData(order, email, userId),
    dataLayerName: 'PageDataLayer',
  });

  return (
    <div className="CartModal-success text-center">
      <img src="/assets/images/flowfo-modal-unlocked.svg" alt="success" />
      <div className="h3 flowfont-title text-primary"> Successful!</div>
      <div className="h3 flowfont-medium text-secondary fs-125">Files are now unlocked.</div>
      <p className="flowfont-regular text-neutral">Thanks for supporting indie creation with us!</p>
    </div>
  );
}

export default memo(CartSuccess);
