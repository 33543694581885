import { GET_FILE_FEEDBACKS, ADD_FILE_FEEDBACK } from '../constants/ActionTypes';

export function getFileFeedbacks(id) {
  return {
    type: GET_FILE_FEEDBACKS,
    id,
  };
}

export function addFileFeedback(id, feedback) {
  return {
    type: ADD_FILE_FEEDBACK,
    id,
    feedback,
  };
}
