import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';

export const MENU_TIMER = 5000; // ms
export const DOUBLE_TAP_SENSITIVITY = 200; // ms
export const BIGSCROLL_NUMBER = 4.0; // how many wheel to active bigscroll
export const BIGSCROLL_TIMER = 1000; // how many time to do BIGSCROLL_NUMBER wheel
export const BIGSCROLL_INTERVAL = 500; // minimum time between each bigscroll
export const BIGSCROLL_TRANSITION_TIME = 350;
export const WEBTOON_BIGSCROLL_TRANSITION_TIME = 950;
export const WEBTOON_BIGSCROLL_HEIGHT_RATIO = 0.8; // of screen height
export const WEBTOON_SCROLL_SPEED_RATIO = 3.5; // of initial drag move
export const WEBTOON_LOADING_PAGE_HEIGHT = '55vh';
export const MARK_AS_READ_TIMEOUT = 500;
export const OPENING_INFO_TIMEOUT = 3000;

export const MODE_FITWIDTH = 'fitwidth';
export const MODE_FITBOX = 'fitbox';
export const MODE_FITSCREEN = 'fitscreen';
export const MODE_FITCOMMENTS = 'fitcomments';

export const VIEWMODE_SEQ = {};
VIEWMODE_SEQ[MODE_FITBOX] = MODE_FITSCREEN;
VIEWMODE_SEQ[MODE_FITSCREEN] = MODE_FITWIDTH;
VIEWMODE_SEQ[MODE_FITWIDTH] = MODE_FITSCREEN;
VIEWMODE_SEQ[MODE_FITCOMMENTS] = MODE_FITSCREEN;

export const READING_NA = 'N/A';
export const READING_LEFT_TO_RIGHT = 'LEFT_TO_RIGHT';
export const READING_RIGHT_TO_LEFT = 'RIGHT_TO_LEFT';
export const READING_TOP_TO_BOTTOM = 'TOP_TO_BOTTOM';

export const SLIDE_ANIMATED = false;

export const PINCH_MIN_SCALE = 1;
export const PINCH_MAX_SCALE = 2.5;
export const PINCH_SETTLE_RANGE = 0.001;
export const PINCH_ADDITIONAL_LIMIT = 0.2;
export const PINCH_DOUBLE_TAP_THRESHOLD = 300;
export const PINCH_ANIMATION_SPEED = 0.04;
export const PINCH_RESET_ANIMATION_SPEED = 0.08;
export const PINCH_INITIAL_X = 0;
export const PINCH_INITIAL_Y = 0;
export const PINCH_INITIAL_SCALE = 1;
export const PINCH_SENSITIVITY = 30; // ms
export const TOUCH_MOVE_SENSITIVIY_X = 4.5; // factor
export const TOUCH_MOVE_SENSITIVIY_Y = 5.5; // factor

export const SWIPE_DIRECTION_LEFT = 'left';
export const SWIPE_DIRECTION_RIGHT = 'right';
export const SWIPE_DIRECTION_DOWN = 'down';
export const SWIPE_DIRECTION_UP = 'up';
export const SWIPE_DIRECTION_NONE = 'none';

export const SCROLL_DIRECTION_UP = 'UP';
export const SCROLL_DIRECTION_DOWN = 'DOWN';

let scrollCount = 0; // Handle BigScroll
let lastBigscrollTime = 0;
let callbackTimers = [];

// load image and get informations
export function loadImage(url) {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener('load', () => {
      resolve(img);
    });
    img.addEventListener('error', () => {
      // Sentry.captureMessage(`loadImage: cannot load ${url}`);
      reject(img);
    });
    img.src = url;
  });
  return promise;
}

export const getSwipeDirection = touchObject => {
  if (!touchObject.lastX && !touchObject.lastY) {
    return SWIPE_DIRECTION_NONE;
  }
  const xDist = touchObject.lastX - touchObject.curX;
  const yDist = touchObject.lastY - touchObject.curY;
  const r = Math.atan2(yDist, xDist);
  let swipeAngle = Math.round((r * 180) / Math.PI);
  if (swipeAngle < 0) {
    swipeAngle = 360 - Math.abs(swipeAngle);
  }
  if ((swipeAngle <= 45 && swipeAngle >= 0) || (swipeAngle <= 360 && swipeAngle >= 315)) {
    return SWIPE_DIRECTION_LEFT;
  }
  if (swipeAngle >= 135 && swipeAngle <= 225) {
    return SWIPE_DIRECTION_RIGHT;
  }
  if (swipeAngle >= 35 && swipeAngle <= 135) {
    return SWIPE_DIRECTION_UP;
  }
  return SWIPE_DIRECTION_DOWN;
};

export const getImageInfo = (target, image) => {
  if (!image) {
    return {};
  }
  const imgRatio = target.clientWidth / image.naturalWidth;
  const currImgWidth = imgRatio < 1 ? Math.round(image.naturalWidth * imgRatio) : image.naturalWidth;
  const currImgHeight = imgRatio < 1 ? Math.round(image.naturalHeight * imgRatio) : image.naturalHeight;
  return {
    element: image,
    currImgWidth,
    currImgHeight,
    width: image.width,
    height: image.height,
    top: image.offsetTop,
    left: image.offsetLeft,
    imgRatio,
    maxScroll: currImgHeight > target.clientHeight ? target.clientHeight - currImgHeight : 0,
  };
};

export const getScrollInfo = (target, event) => {
  const delta = event ? Math.sign(event.deltaY) : 1; // normalize crossbrowser
  const smallScrollDelta = Math.round(target.clientWidth / 10);
  return {
    rootDelta: event ? event.deltaY : undefined,
    small: smallScrollDelta,
    scroll: delta * smallScrollDelta,
    direction: delta < 0 ? SCROLL_DIRECTION_UP : SCROLL_DIRECTION_DOWN,
  };
};

export const initBigScroll = () => {
  scrollCount = 0;
  lastBigscrollTime = 0;
  callbackTimers = [];
};

export const scrollCountDown = () => {
  if (scrollCount > 0) {
    scrollCount -= 1;
  }
};

export const cleanBigScroll = () => {
  scrollCount = 0;
  lastBigscrollTime = 0;
  clearTimeout(scrollCountDown);
  if (callbackTimers.length) {
    callbackTimers.forEach(timer => clearTimeout(timer));
    callbackTimers = [];
  }
};

export const isBigScrollAvailable = () =>
  scrollCount <= BIGSCROLL_NUMBER && Date.now() - lastBigscrollTime > BIGSCROLL_INTERVAL;

export const addBSscroll = () => {
  scrollCount += 1;
  callbackTimers.push(setTimeout(scrollCountDown, Math.round(BIGSCROLL_TIMER / BIGSCROLL_NUMBER)));
};

export const isBScount = () => scrollCount === BIGSCROLL_NUMBER;

export const registerBS = () => {
  lastBigscrollTime = Date.now();
  scrollCount = 0;
};

export const changePlayerUrl = (type, id, fileId, artworkUniqueName) => {
  let playerPath = `/read/${type}/${id}`;
  if (type === 'pack') {
    playerPath = `/read/${artworkUniqueName || '_'}/pack/${id}`;
  } else if (type === 'flow') {
    playerPath = `/read/flow/${artworkUniqueName || id}`;
  } else {
    // news
    playerPath = `/read/${type}/${id}`;
  }
  if (fileId) {
    playerPath += `/file/${fileId}`;
  }
  // eslint-disable-next-line no-restricted-globals
  history.pushState(null, null, playerPath);
  TagManager.dataLayer({
    dataLayer: {
      userId: acl.getUserId(),
      page: playerPath,
    },
    dataLayerName: 'PageDataLayer',
  });
  // ReactGA.set({ userId: acl.getUserId(), page: playerPath, dimension1: process.env.RAZZLE_VERSION_ID });
  // ReactGA.pageview(playerPath);
  return playerPath;
};

export const getImageCenter = (initialImageWidth, initialImageHeight, scale) => ({
  x: (-initialImageWidth * scale) / 2 + initialImageWidth / 2,
  y: (-initialImageHeight * scale) / 2 + initialImageHeight / 2,
});

export const getPointFromTouch = (touch, element, offsetTop) => {
  const rect = element.getBoundingClientRect();
  return {
    x: touch.clientX - rect.left,
    y: touch.clientY - rect.top - (offsetTop || 0),
  };
};

export const getMidpoint = (pointA, pointB) => ({
  x: (pointA.x + pointB.x) / 2,
  y: (pointA.y + pointB.y) / 2,
});

export const getDistanceBetweenPoints = (pointA, pointB) =>
  Math.sqrt((pointA.y - pointB.y) ** 2 + (pointA.x - pointB.x) ** 2);

export const between = (min, max, value) => Math.min(max, Math.max(min, value));

export const inverse = x => x * -1;
