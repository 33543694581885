import * as Sentry from '@sentry/react';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';

import acl from 'tools/acl';
import { TagManager } from 'tools/analytics';
import artworkUtils from 'tools/artwork';

import { removeComment, reportComment } from 'actions/CommentActions';
import { showNotification } from 'actions/NotificationsActions';

import CommentCardList from 'components/CommentCardList';
import CommentForm from 'components/CommentForm';
import Cover from 'components/Cover';

import PlayerSVGs from 'svgs/player';

import './PlayerComments.scss';

const SITE_PUBLIC_URL = process.env.RAZZLE_PUBLIC_URL;

type Props = {
  file: String,
  // flowName: String,
  artworkAuthor: Object,
  filePath: string,
  handleCloseComments: Function,
  onVisitorComment: Function,
};

export default function PlayerComments({
  file,
  filePath,
  artworkAuthor,
  handleCloseComments,
  onVisitorComment,
}: Props) {
  const dispatch = useDispatch();
  const { t } = useTranslation('playercomments');

  const userProfile = useSelector(state => state.user.privateProfile);
  const posts = useSelector(state => state.comment.fileComments);

  const onDelete = id => {
    dispatch(removeComment(id));
  };

  const onReport = id => {
    dispatch(reportComment(id, null, userProfile.id));
  };

  const shareFile = () => {
    const shareLink = SITE_PUBLIC_URL + filePath;
    if (navigator.share) {
      navigator
        .share({
          // title: file.file.name,
          // text: `Take a look at ${file.file.name} of ${flowName} on Flowfo !`,
          url: shareLink,
        })
        .then(() =>
          // ReactGA.event({
          //   category: 'Player',
          //   action: 'Share link',
          //   label: shareLink,
          //   transport: 'beacon',
          // }),
          TagManager.dataLayer({
            dataLayer: {
              action: 'Share link',
              label: shareLink,
            },
            dataLayerName: 'PlayerDataLayer',
          }),
        )
        .catch(error => Sentry.captureException(error));
    } else if (navigator.clipboard) {
      navigator.clipboard.writeText(shareLink).then(
        () => {
          dispatch(showNotification({ level: 'info', message: t('share-link-copied') }));
        },
        err => {
          dispatch(
            showNotification({
              level: 'warning',
              message: t('share-link-failed'),
            }),
          );
          Sentry.captureException(err);
        },
      );
    } else {
      Sentry.captureMessage(t('Cannot copy link to your clipboard, navigator.clipboard not found'));
      dispatch(
        showNotification({
          level: 'warning',
          message: t('share-link-failed'),
        }),
      );
    }
  };

  return (
    <div className="Player-comments">
      <div className="Player-comments-header">
        <Cover src={artworkUtils.getFileUrl(file.file, 42)} />
        <div className="Player-comments-title text-neutral">
          {file.file.name}
          <Button variant="link" className="p-0 mt-1 primary flowfont-regular" onClick={shareFile}>
            <PlayerSVGs.Share width="16" fill="var(--primary)" /> {t('share-link-action')}
          </Button>
        </div>
        <div className="Player-comments-close" onClick={handleCloseComments} role="presentation">
          <PlayerSVGs.Close width="24" height="24" fill="#707070" />
        </div>
      </div>
      {acl.isConnected(userProfile) && <CommentForm fileId={file.file.id} />}
      {!acl.isConnected(userProfile) && (
        <Button
          variant="outline-secondary"
          size="sm"
          className="btn-pill border-secondary mt-3"
          onClick={onVisitorComment}
        >
          {t('Add a comment')}
        </Button>
      )}
      <div className="Player-comments-posts">
        <CommentCardList
          list={posts}
          artworkAuthor={artworkAuthor}
          userProfile={userProfile}
          onDelete={onDelete}
          onReport={onReport}
        />
      </div>
      <div className="Player-comments-footer" />
    </div>
  );
}
