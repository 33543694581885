import cx from 'classnames';

import React, { Component, memo } from 'react';

import Loading from 'components/Loading';
import imgLoadFailed from 'components/Player/PlayerSlide/embeded';

import {
  getImageInfo,
  MODE_FITWIDTH,
  READING_RIGHT_TO_LEFT,
  MODE_FITCOMMENTS,
  READING_TOP_TO_BOTTOM,
  isBigScrollAvailable,
  addBSscroll,
  isBScount,
  registerBS,
  initBigScroll,
  cleanBigScroll,
  getScrollInfo,
  BIGSCROLL_TRANSITION_TIME,
  PINCH_MIN_SCALE,
  PINCH_SENSITIVITY,
  SCROLL_DIRECTION_DOWN,
  SCROLL_DIRECTION_UP,
  WEBTOON_LOADING_PAGE_HEIGHT,
} from '../Player-utils';
import './PlayerSlide.scss';

const IMAGE_STATE = {
  LOAD: null,
  SUCCESS: true,
  ERROR: false,
};

type Props = {
  viewMode: string,
  readingDirection: string,
  slideUpdated: boolean,
  scroll: any,
  pinch: any,
  file: Object,
  index: number,
  imgError: string,
  lazy: boolean,
  changeSlideHandler(): void,
  onImgError(): void,
  onMouseOut(): void,
  onMouseOver(): void,
};
class PlayerSlide extends Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      scrollTo: 0,
      isImageReady: IMAGE_STATE.LOAD,
      show: !props.lazy,
      active: false,
    };
    this.handleWheel = this.handleWheel.bind(this);
  }

  componentDidMount() {
    const { readingDirection } = this.props;
    const { show } = this.state;
    if (show) {
      this.preloadSrc();
    }
    if (readingDirection !== READING_TOP_TO_BOTTOM) {
      initBigScroll();
    }
  }

  componentWillUnmount() {
    cleanBigScroll();
  }

  //
  // preloading event handlers
  //
  onLoadSuccess = e => {
    const { imgError, onImgError } = this.props;
    if (imgError && e.target.src !== imgLoadFailed) {
      onImgError(false);
    }
    this.setState({ isImageReady: IMAGE_STATE.SUCCESS });
    this.freePreloadDependencies();
  };

  onLoadError = e => {
    const { onImgError } = this.props;
    if (e.target.src !== imgLoadFailed) {
      // Sentry.captureMessage(`PlayerSlide: cannot load ${file.file.contentUrl}`);
      onImgError(true);
    }
    this.setState({ isImageReady: IMAGE_STATE.ERROR });
    this.freePreloadDependencies();
  };

  setVisible(show) {
    if (show) {
      this.preloadSrc();
    } else {
      this.freePreloadDependencies();
    }
    this.setState({ show });
  }

  setActive(active) {
    this.setState({ active });
  }

  getImageInfo() {
    const position = this.computeImagePosition();
    return { position, ...getImageInfo(this.slide, this.image) };
  }

  preloadSrc = () => {
    const { file } = this.props;
    this.loader = new Image();
    this.loader.onload = this.onLoadSuccess;
    this.loader.onerror = this.onLoadError;
    this.loader.src = file.file.contentUrl;
  };

  // beware this one is important
  // using timer on component, requires careful cleanup
  // plus we are using image & events to manipulate state, requires careful cleanup
  freePreloadDependencies = () => {
    if (this.loader) {
      this.loader.onload = null;
      this.loader.onerror = null;
      this.loader = null; // kill image[& it's attached events] on unmounted component
    }
  };

  /* ******************************
   * WINDOW EVENT
   ****************************** */
  computeImagePosition() {
    const { viewMode, scroll, readingDirection, index } = this.props;
    const { scrollTo } = this.state;
    const position = {
      left: 0,
      top: 0,
    };
    if (viewMode === MODE_FITWIDTH && this.image && this.image.naturalHeight > 0) {
      const { clientHeight, clientWidth } = this.slide;
      let currImgHeight = this.image.naturalHeight;
      if (this.image.naturalWidth > clientWidth) {
        const imgRatio = clientWidth / this.image.naturalWidth;
        currImgHeight = this.image.naturalHeight * imgRatio;
      }
      const newScroll = scrollTo + scroll;
      // center image or use scroll information
      position.left = this.image.naturalWidth < clientWidth ? (clientWidth - this.image.naturalWidth) / 2 : 0;
      position.top = currImgHeight < clientHeight ? (clientHeight - currImgHeight) / 2 : newScroll;
      if (readingDirection === READING_TOP_TO_BOTTOM) {
        position.top += index * clientHeight;
      }
    }
    return position;
  }

  scrollToPosition(position) {
    if (position === 'END') {
      const imageInfo = getImageInfo(this.slide, this.image);
      this.setState({ scrollTo: imageInfo.maxScroll });
    } else if (position === 'BEGIN') {
      this.setState({ scrollTo: 0 });
    }
  }

  // SCROLL / BIGSCROLL / NEXT / PREVIOUS
  handleWheel(event) {
    const { viewMode, slideUpdated } = this.props;
    // scroll viewport
    if (isBigScrollAvailable() && slideUpdated) {
      const scrollInfo = getScrollInfo(this.slide, event);
      addBSscroll();
      if (isBScount()) {
        registerBS();
        this.runBigScroll(scrollInfo.direction);
      } else if (viewMode === MODE_FITWIDTH) {
        // normal scroll only on fitwidth
        this.handleScroll(scrollInfo.scroll);
      }
    }
  }

  runBigScroll(direction) {
    const { viewMode, changeSlideHandler, readingDirection } = this.props;
    const { scrollTo } = this.state;
    const target = this.slide;
    const oldScrollValue = scrollTo;
    let delta = direction === SCROLL_DIRECTION_UP ? -1 : 1;
    const smallScrollDelta = Math.round(target.clientWidth / 10);
    const bigScroll = Math.round((2 * target.clientWidth) / 3.0 - 3 * smallScrollDelta);
    const newScrollDelta = bigScroll * delta;
    // const newScrollValue = Math.round(oldScrollValue - newScrollDelta);
    if (viewMode !== MODE_FITWIDTH || readingDirection === READING_TOP_TO_BOTTOM) {
      if (readingDirection === READING_RIGHT_TO_LEFT) delta = -delta;
      changeSlideHandler(delta < 0 ? 'PREVIOUS' : 'NEXT');
      return;
    }

    // compute image size displayed
    const imageInfo = getImageInfo(target, this.image);

    // console.log(image, newScroll, maxScroll);
    if (direction === SCROLL_DIRECTION_UP) {
      // const fromLimit = -Math.round(target.clientWidth / 100.0);
      // console.log('UP: fromLimit', fromLimit, 'oldScrollValue',
      // oldScrollValue, 'newScroll', newScrollValue, 'maxScroll', imageInfo.maxScroll);
      if (oldScrollValue === 0) {
        // PREVIOUS
        if (readingDirection === READING_RIGHT_TO_LEFT) {
          changeSlideHandler('NEXT');
        } else {
          changeSlideHandler('PREVIOUS');
        }
        return;
      }
    } else if (direction === SCROLL_DIRECTION_DOWN) {
      // const fromLimit = (imageInfo.maxScroll === 0)
      // ? 0 : imageInfo.maxScroll + (smallScrollDelta * delta);
      // console.log(this.lastBigscrollTime, 'DOWN: fromLimit', fromLimit, 'oldScrollValue',
      //  oldScrollValue, 'newScroll', newScrollValue, 'maxScroll', imageInfo.maxScroll);
      if (oldScrollValue === imageInfo.maxScroll) {
        // NEXT
        if (readingDirection === READING_RIGHT_TO_LEFT) {
          changeSlideHandler('PREVIOUS');
        } else {
          changeSlideHandler('NEXT');
        }
        return;
      }
    }
    this.handleScroll(newScrollDelta);
  }

  handleScroll(delta) {
    const { scrollTo } = this.state;
    const target = this.slide;
    const oldScrollValue = scrollTo;

    let newScroll = Math.round(oldScrollValue - delta);

    // compute image size displayed
    const imageInfo = getImageInfo(target, this.image);

    // don't go off screen
    if (newScroll > 0) {
      newScroll = 0;
    }
    if (newScroll < imageInfo.maxScroll) {
      newScroll = imageInfo.maxScroll;
    }
    this.setState({ scrollTo: newScroll });
  }

  render() {
    const { file, viewMode, readingDirection, pinch, imgError, debug, onMouseOver, onMouseOut } = this.props;
    const { scrollTo, show, active, isImageReady } = this.state;
    const isWebToon = readingDirection === READING_TOP_TO_BOTTOM;
    const position = this.computeImagePosition();
    const style = { left: `${position.left}px` };
    if (!isWebToon && scrollTo !== 0) {
      style.transform = `translateY(${position.top}px)`;
      style.transition = `${BIGSCROLL_TRANSITION_TIME}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`;
    } else if (!isWebToon) {
      const top = position.top + (pinch?.y || 0);
      const left = pinch?.x || 0;
      // style.top = `${position.top}px`;
      style.pointerEvents = (pinch?.scale || PINCH_MIN_SCALE) === 1 ? 'auto' : 'none';
      style.transform = `translate3d(${left}px, ${top}px, 0) scale(${pinch?.scale || PINCH_MIN_SCALE})`;
      style.transformOrigin = '0 0';
      style.transition = `${PINCH_SENSITIVITY}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`;
    }

    const src = show ? file.file.contentUrl : null;
    return (
      <div
        key={file.file.id}
        className={cx('Player-slide', { 'd-flex': isWebToon, show, hide: !show, active, debug })}
        ref={c => {
          this.slide = c;
        }}
      >
        <div className={cx('Player-slide-wrapper d-flex', `Player-${viewMode}`, { webtoon: isWebToon })}>
          {show && isImageReady === IMAGE_STATE.LOAD && (
            <div
              className="mx-auto my-auto d-flex"
              style={{ minHeight: isWebToon ? WEBTOON_LOADING_PAGE_HEIGHT : undefined, height: 80 }}
            >
              <Loading className="my-auto" />
            </div>
          )}
          {show && isImageReady !== IMAGE_STATE.LOAD && (
            <img
              ref={c => {
                this.image = c;
              }}
              src={isImageReady === IMAGE_STATE.ERROR || imgError === true ? imgLoadFailed : src}
              style={style}
              alt={file.file.name}
              id={`img-${file.file.id}`}
              onMouseOver={onMouseOver}
              onFocus={onMouseOver}
              onMouseOut={onMouseOut}
              onBlur={onMouseOut}
              onLoad={this.onLoadSuccess}
              onError={this.onLoadError}
            />
          )}
          <div
            id={`Player-comments-ghost-${file.file.id}`}
            className={cx('Player-comments-ghost', {
              webtoon: isWebToon,
              show: viewMode === MODE_FITCOMMENTS,
              hide: viewMode !== MODE_FITCOMMENTS,
            })}
          />
        </div>
      </div>
    );
  }
}

export default memo(PlayerSlide);
