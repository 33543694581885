import cx from 'classnames';

import React, { memo } from 'react';

import { MODE_FITSCREEN, READING_RIGHT_TO_LEFT } from 'components/Player/Player-utils';

import PlayerSVGs from 'svgs/player';

import styles from './Arrows.module.scss';

interface Props {
  currIndex: number;
  files?: Object[];
  showMenu: boolean;
  viewMode: string;
  readingDirection: string;
  isLoading: boolean;
}

export default memo(function Arrows({ currIndex, files, showMenu, viewMode, readingDirection, isLoading }: Props) {
  const show = viewMode === MODE_FITSCREEN && showMenu;
  return (
    <div className={cx(styles.Arrows, { [styles.show]: show, [styles.hide]: !show && !isLoading })}>
      <PlayerSVGs.ArrowLeft
        color="var(--distant)"
        className={cx(
          currIndex === 0 && readingDirection !== READING_RIGHT_TO_LEFT ? 'hide' : 'show',
          'fadeInRight',
          'delay-1s',
          { animated: isLoading },
        )}
      />
      <PlayerSVGs.ArrowRight
        color="var(--distant)"
        className={cx(
          currIndex === files?.length - 1 && readingDirection === READING_RIGHT_TO_LEFT ? 'hide' : 'show',
          'fadeInLeft',
          'delay-1s',
          { animated: isLoading },
        )}
      />
    </div>
  );
});
