import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import url from 'tools/url';

import { hideCart } from 'actions/UserActions';

import Loading from 'components/Loading';

import CommunSVGs from 'svgs/commun';

import './CartModal.scss';
import CartContent from './partials/CartContent';
import CartSuccess from './partials/CartSuccess';

type Props = {
  onCancel(): void,
};

export default function CartModal({ onCancel }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const userProfile = useSelector(state => state.user.privateProfile);
  const cart = useSelector(state => state.user.cart);
  const cartArtworkId = useSelector(state => state.user.cartArtworkId);
  const cartMode = useSelector(state => state.user.cartMode);
  const cartLastOrder = useSelector(state => state.user.cartLastOrder);

  const [show, setShow] = useState(true);
  const [tab, setTab] = useState();

  const artworkId = url.isArtworkPage(location.pathname) ? cartArtworkId : null;
  const isPlayer = url.isPlayerPage(window.location.href); // to handle history.pushState for player urls

  const handleCancel = () => {
    if (onCancel) onCancel();
    setShow(false);
    dispatch(hideCart());
  };

  const renderLoading = () => (
    <div className="CartModal-loading">
      <Loading />
    </div>
  );

  const renderSuccessFree = () => (
    <div className="CartModal-success">
      <img src="/assets/images/flowfo-modal-purchased-free.svg" alt="success" />
    </div>
  );

  return (
    <Modal show={show} onHide={handleCancel} centered dialogClassName="CartModal">
      <Modal.Body>
        <div className="CartModal-close" onClick={handleCancel} role="presentation">
          <CommunSVGs.Close width="20" height="20" fill="var(--neutral)" />
        </div>
        {tab && (
          <div className="CartModal-previous" role="presentation" onClick={() => setTab(false)}>
            <CommunSVGs.ArrowLeft width="20" height="20" fill="var(--neutral)" />
          </div>
        )}
        <div className="CartModal-content text-center">
          {cartMode === 'cart' && (
            <CartContent
              userProfile={userProfile}
              cart={cart}
              artworkId={artworkId}
              isPlayer={isPlayer}
              tab={tab}
              onChangeTab={value => setTab(value)}
              onClose={handleCancel}
            />
          )}
          {cartMode === 'fetching' && renderLoading()}
          {cartMode === 'success' && (
            <CartSuccess order={cartLastOrder} email={userProfile.email} userId={userProfile.id} />
          )}
          {cartMode === 'success_free' && renderSuccessFree()}
        </div>
      </Modal.Body>
    </Modal>
  );
}
