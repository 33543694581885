import React from 'react';

import {
  isBigScrollAvailable,
  addBSscroll,
  isBScount,
  registerBS,
  initBigScroll,
  cleanBigScroll,
  getScrollInfo,
  WEBTOON_BIGSCROLL_TRANSITION_TIME,
  WEBTOON_BIGSCROLL_HEIGHT_RATIO,
  SCROLL_DIRECTION_UP,
} from '../Player-utils';

type Props = {
  initialSlide: Number,
  slides: [],
  onInit(): void,
  beforeChange(): void,
  afterChange(): void,
  onLastFile(): void,
  debugEvent(): void,
  debug: boolean,
  className: String,
  children: Object,
};

class PlayerWebtoonSlider extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      track: 0,
      curIndex: props.initialSlide,
    };
  }

  componentDidMount() {
    const { onInit, initialSlide } = this.props;
    initBigScroll();
    const scrollLimit = this.computeScrollLimit(initialSlide);
    this.activeSlide(initialSlide, true);
    this.showSlide(initialSlide, true);
    this.showSlide(initialSlide + 1, true);
    setTimeout(() => {
      this.showSlide(initialSlide - 1, true);
      this.showSlide(initialSlide + 2, true);
    });
    this.setState({ track: scrollLimit.min }, onInit());
  }

  componentWillUnmount() {
    cleanBigScroll();
  }

  getSlide(index) {
    const { slides } = this.props;
    if (!slides || index < 0 || index > slides.length) {
      return null;
    }
    return slides[index];
  }

  slickGoTo = index => {
    const { beforeChange, afterChange, slides } = this.props;
    const { curIndex } = this.state;
    if (!slides || index < 0 || index >= slides.length) {
      return;
    }
    beforeChange(curIndex, index);
    this.activeSlide(curIndex, false);
    this.activeSlide(index, true);
    this.showSlide(index, true);
    this.showSlide(index + 1, true);
    setTimeout(() => {
      this.showSlide(index + 2, true);
      this.showSlide(index - 1, true);
    });
    const scrollLimit = this.computeScrollLimit(index);
    this.setState({ curIndex: index, track: scrollLimit.min }, afterChange(index));
  };

  runBigScroll = direction => {
    const delta = direction === SCROLL_DIRECTION_UP ? -1 : 1;
    const bigScroll = Math.round(this.webtoonSlider.clientHeight * WEBTOON_BIGSCROLL_HEIGHT_RATIO);
    const newScrollDelta = bigScroll * delta;

    this.handleScroll(newScrollDelta);
  };

  handleWheel = (event, manual) => {
    const { slides } = this.props;
    const { curIndex } = this.state;
    // scroll viewport
    if (manual || isBigScrollAvailable()) {
      const scrollInfo = getScrollInfo(slides[curIndex].slide, event);
      let isNormalScroll = true;
      if (!manual) {
        addBSscroll();
        if (isBScount()) {
          registerBS();
          isNormalScroll = false;
          this.runBigScroll(scrollInfo.direction);
        }
      }
      if (manual) {
        this.handleScroll(scrollInfo.rootDelta);
      } else if (isNormalScroll) {
        this.handleScroll(scrollInfo.scroll);
      }
    }
  };

  handleScroll(delta) {
    const { slides, beforeChange, afterChange, onLastFile } = this.props;
    const { curIndex, track } = this.state;
    const isLastPage = curIndex >= slides.length - 1;
    const oldScrollValue = track;

    const newDelta = Math.round(oldScrollValue + delta);
    const updatedState = {
      track: newDelta > 0 ? newDelta : 0, // don't go off screen
    };

    const scrollLimit = this.computeScrollLimit(curIndex);
    // console.log(delta, oldScrollValue, updatedState.track, scrollLimit);
    if (delta > 0 && updatedState.track > scrollLimit.maxScreen) {
      if (!isLastPage) {
        beforeChange(curIndex, curIndex + 1);
        this.activeSlide(curIndex, false);
        updatedState.curIndex = curIndex + 1;
        this.activeSlide(updatedState.curIndex, true);
        this.showSlide(updatedState.curIndex + 1, true);
        setTimeout(() => this.showSlide(updatedState.curIndex + 2, true));
      } else {
        updatedState.track = scrollLimit.maxScreen;
        if (onLastFile) onLastFile();
      }
    } else if (delta < 0 && (updatedState.track < scrollLimit.min || (newDelta < 0 && curIndex > 0))) {
      if (curIndex > 0) {
        beforeChange(curIndex, curIndex - 1);
        this.activeSlide(curIndex, false);
        updatedState.curIndex = curIndex - 1;
        this.activeSlide(updatedState.curIndex, true);
        this.showSlide(updatedState.curIndex, true);
        setTimeout(() => this.showSlide(updatedState.curIndex - 1, true));
        if (newDelta < 0) {
          const previousScrollLimit = this.computeScrollLimit(updatedState.curIndex);
          updatedState.track = previousScrollLimit.max + newDelta;
        }
      } else {
        updatedState.track = scrollLimit.min;
      }
    }
    this.setState(updatedState, () => {
      if (updatedState.curIndex) {
        afterChange(updatedState.curIndex);
      }
    });
  }

  computeScrollLimit(index) {
    const { slides } = this.props;
    if (!slides || slides.length === 0 || index < 0) {
      return { min: 0, max: 0, middle: 0, offset: 0 };
    }
    const indexUpdate = index < slides.length ? index : slides.length - 1;
    let min = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < slides.length && i < indexUpdate; i++) {
      min += slides[i].slide.clientHeight;
    }
    const midScreen = this.webtoonSlider.clientHeight / 2;
    const slideHeight = slides[indexUpdate].slide.clientHeight;
    const max = min + slideHeight;
    return {
      min,
      max,
      middle: min + slideHeight / 2,
      minScreen: slideHeight > midScreen ? min + midScreen : min,
      maxScreen: slideHeight > midScreen ? max - midScreen : max,
      offset: slides[indexUpdate]?.image?.offsetTop,
    };
  }

  activeSlide(index, active) {
    // const { debugEvent } = this.props;
    const slide = this.getSlide(index);
    if (!slide) return;
    slide.setActive(active);
    // debugEvent('active', index);
  }

  showSlide(index, show) {
    const { debug, debugEvent } = this.props;
    const slide = this.getSlide(index);
    if (!slide) return;
    slide.setVisible(show);
    if (debug) debugEvent('show', index);
  }

  render() {
    const { className, children, initialSlide } = this.props;
    const { track, curIndex } = this.state;
    const index = curIndex || initialSlide || 0;
    // const scrollLimit = this.computeScrollLimit(index);
    const nextLimit = this.computeScrollLimit(index + 1);
    const prevLimit = this.computeScrollLimit(index - 1);

    let newTrack = track;
    if (newTrack < prevLimit.minScreen) newTrack = prevLimit.minScreen;
    if (newTrack > nextLimit.maxScreen) newTrack = nextLimit.maxScreen;
    // console.log(track, newTrack, prevLimit, nextLimit);
    return (
      <div
        ref={c => {
          this.webtoonSlider = c;
        }}
        className={`${className} slick-track`}
        style={{
          transform: `translateY(-${newTrack}px)`,
          transition: `${WEBTOON_BIGSCROLL_TRANSITION_TIME}ms cubic-bezier(0.25, 0.46, 0.45, 0.94)`,
        }}
      >
        {children}
      </div>
    );
  }
}

export default React.memo(PlayerWebtoonSlider);
